import * as React from 'react';
import { CSVLink } from 'react-csv';
import DataTable, { TableColumn } from 'react-data-table-component';

import FlexContainer from 'global_elements/Layouts/FlexContainer';
import InlineText from 'global_elements/Text/InlineText';
import { ReactComponent as SortIcon } from 'icons/mho-sort-icon-white.svg';

import { FacilityUserAccountsTableProps, FacilityUserDataRow } from 'types/tableProps';
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';

import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Utilities from 'api/lib/Utilities';
import { HoverTableStyles } from '../styles';

const isMobile = window.innerWidth <= 1024;

const NoDataComponent: JSX.Element = <InlineText text="No Results Found." fontColor={FontColors.PRIMARY} fontSize={FontSizes.LARGE} />;

const columns = (onChangeAccessType: (row: FacilityUserDataRow, e: React.MouseEvent<Element, MouseEvent>) => any, isCoreMeasuresEnabled: boolean): TableColumn<FacilityUserDataRow>[] => {
  const cols: TableColumn<FacilityUserDataRow>[] = [
    {
      name: 'Name',
      selector: (row) => row.name.toLowerCase(),
      format: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: 'Corporation',
      selector: (row) => row.corporation,
      sortable: true,
      maxWidth: isMobile ? '125px' : '150px',
    },
    {
      name: 'Facility',
      selector: (row) => row.facility,
      sortable: true,
      maxWidth: isMobile ? '200px' : '300px',
    },
    {
      name: 'Role',
      selector: (row) => row.role,
      sortable: true,
      maxWidth: '166px',
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      sortable: true,
      maxWidth: isMobile ? '70px' : '100px',
    },
  ];
  if (isCoreMeasuresEnabled) {
    cols.push({
      name: 'Access',
      selector: (row) => row.accessType,
      sortable: true,
      // eslint-disable-next-line react/jsx-one-expression-per-line
      cell: (row) => (
        <span className="access-type-label">
          {row.accessType}
          {row.status !== 'Inactive' && <FontAwesomeIcon icon={faPencil} style={{ paddingLeft: '5px' }} onClick={(e) => onChangeAccessType(row, e)} />}
        </span>
      ),
    });
  }
  return cols;
};

const FacilityUserAccountsTable = (props: FacilityUserAccountsTableProps): JSX.Element => (
  <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.CENTER} align={AlignVariant.END} extraClasses="facility-user-account-table">
    <CSVLink data={props.data} filename="facility-users.csv" className="btn --primary" target="_blank">
      <InlineText text="Export to CSV" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
    </CSVLink>
    <DataTable
      dense
      highlightOnHover
      persistTableHead
      pagination
      paginationDefaultPage={props.defaultPage}
      sortIcon={<SortIcon />}
      customStyles={HoverTableStyles}
      columns={columns(props.onChangeAccessType, props.isCoreMeasuresEnabled)}
      data={props.data}
      noDataComponent={NoDataComponent}
      onRowClicked={props.onRowClicked}
      sortFunction={Utilities.caseInsensitiveSort}
      onSort={Utilities.setCellTitles}
      onChangePage={props.onChangePage}
    />
  </FlexContainer>
);

export default FacilityUserAccountsTable;
