import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import DataListsProxy, { DataListType } from 'api/dataLists/dataListsProxy';
import PatientUsersProxy from 'api/patientUsers/patientUsersProxy';
import UserProxy from 'api/user/userProxy';
import { FacilityUserAccountState, FacilityProgramList, corporateFacilityList, ProgramFacilityIDMap, FacilityProgramOption, ProgramCorporationIDMap } from 'types/facilityUserAccount';
import { AllRoutes } from 'constants/routes';
import * as EmailInput from 'global_elements/Inputs/TextInput/lib/email';
import { NarrowRoleOptions } from 'global_elements/Inputs/Dropdown/lib/dropDownLists';
import { SelectOption } from 'types/inputProps';
import { ListFacility } from 'interfaces/dataLists/listFacility';
import { ListFacilityProgram } from 'interfaces/dataLists/listFacilityProgram';
import { FacilityAccountData } from 'interfaces/patients/patientData';
import Utilities from 'api/lib/Utilities';
import { FacilityUserTools } from 'lib/Facility/tools';
import { FacilityConst } from 'lib/Facility/facilityConsts';
import { NetworkError } from 'api/lib/ApiService/errors';
import { NETWORK_ERROR_MESSAGE } from 'constants/errorMessages';
import { UserContext } from 'context/user';
import FeatureFlagManager from '../../../../config/featureFlags/FeatureFlagManager';
import { FeatureFlagName } from '../../../../config/featureFlags';

export type FacilityUserPageUrlParams = { accountID: string };

export class FacilityUserPage extends React.Component<RouteComponentProps<FacilityUserPageUrlParams>, FacilityUserAccountState> {
  // eslint-disable-next-line react/static-property-placement
  context!: React.ContextType<typeof UserContext>;

  isRegistration = false;

  constructor(props: RouteComponentProps<FacilityUserPageUrlParams>, isRegistration = false) {
    super(props);
    this.isRegistration = isRegistration;

    this.state = {
      statusOptions: [],
      roleOptions: [],
      accessTypeOptions: [],
      availableCorporations: [],
      corporateFacilitiesIDMap: [],
      firstName: '',
      firstNameError: false,
      lastName: '',
      lastNameError: false,
      email: '',
      emailError: false,
      emailConfirm: '',
      emailConfirmError: false,
      emailIsOpen: true,
      emailDomainError: false,
      userRole: null,
      userRoleError: false,
      accessType: null,
      selectedAccessType: null,
      corporation: null,
      accountStatus: isRegistration ? { label: 'Active', value: '1' } : { label: 'Active', value: 'active' },
      saveError: isRegistration ? false : undefined,
      loading: isRegistration ? undefined : true,

      showAccessTypeSelectionWindow: false,

      facilityProgramLists: [],
      filteredFacilityProgramList: [],
      selectedProgramIDs: [],
      programToFacilitiesIDMap: {},
      programToCorporationIDMap: {},

      accountId: this.props.match.params.accountID,
    };
  }

  /**
   * Retrieve a list of available corporation.
   *
   * @returns {Promise} a list of available SelectOptions of corporation.
   */
  loadCorporationPromise = (): Promise<SelectOption[]> => new Promise<SelectOption[]>((resolve, reject) => {
    DataListsProxy.getUserCorporationList()
      .then((corporationList) => {
        const corporationOptions: SelectOption[] = [];
        const corporateFacilties: corporateFacilityList[] = [];

        corporationList
          .sort((a, b) => {
            if (a.corporationName.toLowerCase() < b.corporationName.toLowerCase()) return -1;
            if (a.corporationName.toLowerCase() > b.corporationName.toLowerCase()) return 1;
            return 0;
          })
          .forEach((element) => {
            if (element.corporationName && element.corporationID) {
              corporationOptions.push({
                label: element.corporationName,
                value: element.corporationID.toString(),
              });
            }
          });

        this.setState((current) => ({ ...current, availableCorporations: corporationOptions }));

        // KWK Removing this for now as it seems no longer necessary.  setState was overwriting these options in
        // cases that was causing it to appear it was never set.
        // if (corporationOptions.length < 2) {
        //   this.handleCorporationSelectChange(corporationOptions[0]);
        // }

        corporationOptions.forEach((corporation) => {
          const facilityIDList: number[] = [];

          FacilityUserTools.getSortedUserFacilities(parseInt(corporation.value, 10))
            .then((facilityList: ListFacility[]) => {
              facilityList.forEach((facility) => {
                facilityIDList.push(facility.facilityID);
              });

              corporateFacilties.push({
                corporationID: parseInt(corporation.value, 10),
                facilityIDs: facilityIDList,
              });
            })
            .catch((errorResponse) => {
              console.log(errorResponse);
            });
        });

        // JBC Come back to this to convert corporateFacilties into a map
        this.setState((current) => ({
          ...current,
          corporateFacilitiesIDMap: corporateFacilties,
        }));
        if (this.state.corporation) {
          const corpID = parseInt(this.state.corporation.value, 10);
          DataListsProxy.getFacilityPrograms(corpID)
            .then((filteredPrograms: ListFacilityProgram[]) => {
              const newProgramLists: FacilityProgramOption[] = FacilityUserTools.getFacilityProgramOptions(filteredPrograms);
              this.setState((current) => ({
                ...current,
                filteredFacilityProgramList: newProgramLists,
              }));
            })
            .catch((error) => {
              console.log(error);
            });
        }
        resolve(corporationOptions);
      })
      .catch((errorResponse) => {
        console.log(errorResponse);
        reject(errorResponse);
      });
  });

  /**
   * Retrieve the User Roles for the async dropdown.
   *
   * @returns {Promise} a list of all user roles.
   */
  loadRoleOptionsPromise = (): Promise<SelectOption[]> => new Promise<SelectOption[]>((resolve, reject) => {
    DataListsProxy.getOptionList(
      DataListType.UserRole,
      (response) => {
        let loadedRoles: SelectOption[] = [];

        response.data?.forEach((element) => {
          if (element.lookupID && element.lookupDesc) {
            loadedRoles.push({ label: element.lookupDesc, value: element.lookupID.toString() });
          }
        });

        loadedRoles = NarrowRoleOptions(this.context.user?.role, loadedRoles, false);
        if (loadedRoles.length > 0) {
          this.setState({ roleOptions: loadedRoles });
        }
        resolve(loadedRoles);
      },
      (errorResponse) => {
        console.log(errorResponse);
        reject(errorResponse);
      },
    );
  });

  loadAccessTypeOptionsPromise = (): Promise<SelectOption[]> => new Promise<SelectOption[]>((resolve, reject) => {
    const facilityId = 0; // 0 = All Facilities/Optional Parameter Currently
    const accountId = parseInt(this.state.accountId, 10);
    DataListsProxy.getAccessTypes(
      facilityId,
      accountId,
      (response) => {
        const loadedAccessTypes: SelectOption[] = [];

        response.data?.forEach((element) => {
          if (element.accessType) {
            loadedAccessTypes.push({ label: element.accessType, value: element.accessTypeID.toString() });
          }
        });

        if (loadedAccessTypes.length > 0) {
          this.setState({ accessTypeOptions: loadedAccessTypes });
        }
        resolve(loadedAccessTypes);
      },
      (errorResponse) => {
        console.log(errorResponse);
        reject(errorResponse);
      },
    );
  });

  loadAccessTypeOptions = (): void => {
    const facilityId = 0; // 0 = All Facilities/Optional Parameter Currently
    const accountId = parseInt(this.state.accountId, 10);
    DataListsProxy.getAccessTypes(
      facilityId,
      accountId,
      (response) => {
        const loadedAccessTypes: SelectOption[] = [];

        response.data?.forEach((element) => {
          if (element.accessType) {
            loadedAccessTypes.push({ label: element.accessType, value: element.accessTypeID.toString() });
          }
        });

        if (loadedAccessTypes.length > 0) {
          if (loadedAccessTypes.find((option) => option.value === this.state.accessType?.value)) {
            this.setState((current) => ({
              ...current,
              selectedAccessType: current.accessType,
            }));
          } else {
            this.setState((current) => ({
              ...current,
              selectedAccessType: loadedAccessTypes[0],
            }));
          }
          this.setState({ accessTypeOptions: loadedAccessTypes });
        }
      },
      (errorResponse) => {
        console.log(errorResponse);
      },
    );
  };

  /**
   * Initialize the Programs.
   *
   * @returns {Promise} true once Programs are set.
   */
  initializePrograms = (): Promise<boolean> => new Promise((resolve, reject) => {
    DataListsProxy.getFacilityPrograms()
      .then((facilityPrograms: ListFacilityProgram[]) => {
        const programFacilityMap: ProgramFacilityIDMap = {};
        const programCoporationMap: ProgramCorporationIDMap = {};
        facilityPrograms.forEach((program) => {
          programFacilityMap[program.programID] = program.facilityID;
          programCoporationMap[program.programID] = program.corporationID;
        });

        const newProgramLists: FacilityProgramOption[] = FacilityUserTools.getFacilityProgramOptions(facilityPrograms);
        this.setState(
          (current) => ({
            ...current,
            facilityProgramLists: newProgramLists,
            programToFacilitiesIDMap: programFacilityMap,
            programToCorporationIDMap: programCoporationMap,
          }),
          () => {
            resolve(true);
          },
        );
      })
      .catch((errorResponse) => {
        console.log(errorResponse);
        this.setState((current) => ({
          ...current,
          facilityProgramLists: [],
          filteredFacilityProgramList: [],
        }));
        reject(errorResponse);
      });
  });

  filterPrograms = (data: FacilityProgramList[], filters: SelectOption[] | null): FacilityProgramList[] => {
    let filteredList: FacilityProgramList[] = [];

    if (filters === null || filters.length === 0) {
      filteredList = data;
    } else {
      for (let i = 0; i < data.length; i += 1) {
        const { facilityName, facilityID } = data[i].facility;
        const hasSelections = facilityName === FacilityConst.ALL_FACILITY_OPTION.label;
        if (hasSelections) {
          // Always push the All Facilities checkbox.
          // MHO-885: Don't push any data from a filter if it's been removed from the filter list, regardless of whether it has checked options.
          filteredList.push(data[i]);
        } else {
          const foundFilter = filters.find((filter) => filter.label === facilityName && filter.value === String(facilityID));
          if (foundFilter) {
            filteredList.push(data[i]);
          }
        }
      }
    }

    if (filteredList.length !== 1) {
      let everythingChecked = true;
      for (let i = 0; i < filteredList.length; i += 1) {
        let facilityChecked = true;

        const foundProgram = filteredList[i].programList.find((program) => !program.isChecked);
        if (foundProgram) {
          everythingChecked = false;
          facilityChecked = false;
        }
        filteredList[i].facilityChecked = facilityChecked;
      }
      filteredList[0].facilityChecked = everythingChecked;
    }

    return filteredList;
  };

  anyProgramSelected = (state: FacilityUserAccountState): boolean => {
    if (state.userRole?.value === '1' || state.userRole?.value === '2') {
      return true;
    }

    return this.state.selectedProgramIDs.length > 0;
  };

  /**
   * Check if the selected user role is admin or super admin.
   *
   * @returns true if the selected user role is admin or super admin.
   */
  isAdminRoleSelected = (): boolean => Utilities.stringAreEqual(this.state.userRole?.label, 'Super Administrator') || Utilities.stringAreEqual(this.state.userRole?.label, 'Administrator');

  isFacilityExecutiveOrFacilityDirectorOrProviderRoleSelected = (): boolean => Utilities.stringAreEqual(this.state.userRole?.label, 'Facility Executive')
    || Utilities.stringAreEqual(this.state.userRole?.label, 'Facility Director')
    || Utilities.stringAreEqual(this.state.userRole?.label, 'Provider');

  /**
   * Check if the email and the confirmation emails match, but is case insensitive.
   *
   * @returns true if the emails match, case insensitive.
   */
  doEmailsMatch = (): boolean => Utilities.stringAreEqual(this.state.email, this.state.emailConfirm);

  isValueSet = (value: any): boolean => !!value;

  /**
   * Validate user account info entered.
   *
   * @returns true if the user account state is valid to save.
   */
  isValidateSaveReady = (): boolean => {
    const isValidCorporation = this.isAdminRoleSelected() || (this.state.corporation && this.state.corporation.value !== '');
    const isValidEmail = EmailInput.validEmail(this.state.email) && this.doEmailsMatch();

    if (
      this.isValueSet(this.state.firstName)
      && this.isValueSet(this.state.lastName)
      && this.isValueSet(this.state.userRole)
      && this.isValueSet(this.state.accountStatus)
      && this.anyProgramSelected(this.state)
      && isValidCorporation
      && (!this.isRegistration || isValidEmail)
    ) {
      return true;
    }

    return false;
  };

  disableRegistration = (): boolean => !this.isValidateSaveReady();

  emptyFirstNameError = (): void => {
    if (this.state.firstName === '') {
      this.setState((current) => ({
        ...current,
        firstNameError: true,
      }));
    } else {
      this.setState((current) => ({
        ...current,
        firstNameError: false,
      }));
    }
  };

  emptyLastNameError = (): void => {
    if (this.state.lastName === '') {
      this.setState((current) => ({
        ...current,
        lastNameError: true,
      }));
    } else {
      this.setState((current) => ({
        ...current,
        lastNameError: false,
      }));
    }
  };

  /**
   * Clear any selected checked programs.
   */
  clearCheckedProgramList = (): void => {
    this.setState((current) => ({ ...current, selectedProgramIDs: [] }));
  };

  /**
   * Event handler for Role change.
   *
   * @param option selected dropdown option.
   */
  handleRoleSelectChange = (option: SelectOption): void => {
    this.setState(
      (current) => ({
        ...current,
        userRole: option,
      }),
      () => {
        this.clearCheckedProgramList();
      },
    );
  };

  handleAccessTypeSelectChange = (option: SelectOption): void => {
    this.setState((current) => ({
      ...current,
      accessType: option,
      selectedAccessType: option,
    }));
  };

  /**
   * Event handler for Corporation change.
   *
   * @param option selected dropdown option.
   */
  handleCorporationSelectChange = (option: SelectOption): void => {
    this.setState((current) => ({
      ...current,
      corporation: option,
    }));
    this.clearCheckedProgramList();

    const isCorpSelected = option.label !== 'All Corporations';
    const corporationID: number | undefined = isCorpSelected ? parseInt(option.value, 10) : undefined;
    DataListsProxy.getFacilityPrograms(corporationID)
      .then((filteredPrograms: ListFacilityProgram[]) => {
        const newProgramLists: FacilityProgramOption[] = FacilityUserTools.getFacilityProgramOptions(filteredPrograms);
        this.setState((current) => ({
          ...current,
          filteredFacilityProgramList: newProgramLists,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleAvailableFacilitySelectChange = (elementID: string, optionValue: number, optionLabel: string): void => {
    const dropdownElem = document.querySelector(`#${elementID}`);
    const searchElem = dropdownElem?.closest('form')?.querySelector('.rdl-available .rdl-filter-container input');
    if (searchElem) {
      Utilities.htmlElementValueSetter(searchElem, optionLabel);
    }
  };

  handleSelectedFacilitySelectChange = (elementID: string, optionValue: number, optionLabel: string): void => {
    const dropdownElem = document.querySelector(`#${elementID}`);
    const searchElem = dropdownElem?.closest('form')?.querySelector('.rdl-selected .rdl-filter-container input');
    if (searchElem) {
      Utilities.htmlElementValueSetter(searchElem, optionLabel);
    }
  };

  onProgramSelectionChange = (selected: any): void => {
    this.setState({ selectedProgramIDs: selected });
  };

  handleProgramSelectChange = (options: SelectOption[]): void => {
    this.setState((current) => ({
      ...current,
      program: options,
    }));
  };

  getCorporationID = (facilityID: number): number => {
    if (facilityID === 0) {
      return 0;
    }

    let corpID = NaN;
    const selectedCorporationID: number | undefined = this.state.corporation ? parseInt(this.state.corporation.value, 10) : undefined;

    this.state.corporateFacilitiesIDMap.forEach((corporation) => {
      corporation.facilityIDs.forEach((fid) => {
        if (facilityID === fid && corporation.corporationID === selectedCorporationID) {
          corpID = corporation.corporationID;
        }
      });
    });

    return corpID;
  };

  /**
   * Find the corporation SelectOption by its ID/value.
   *
   * @param corpID corportation ID to search for.
   * @returns Found SelectOption with the matching ID, or null.
   */
  getCorporationOption = (corpID: number): SelectOption | null => this.state.availableCorporations.find((corp) => corp.value === String(corpID)) || null;

  returnToFacilityUserAccounts = (): void => {
    this.props.history.push(AllRoutes.FACILITY_USER_ACCOUNTS);
  };

  /**
   * Entitlement factory. Create an entitlement associated for the user.
   *
   * @param accountID existing accountID for edit or null for new users.
   * @param facilityID Associated facility ID. (0 for admins).
   * @param programID Associated program ID. (0 for admin).
   * @param userEmail Editing user's email.
   * @returns a created entitlement object.
   */
  createEntitlement = (accountID: number | null = null, facilityID = 0, programID = 0, userEmail = '', corporationID?: number): any => ({
    AccountEntitlementID: null,
    AccountID: accountID,
    StatusID: 1,
    CorporationID: corporationID || this.getCorporationID(facilityID),
    FacilityID: facilityID,
    ProgramID: programID,
    CreatedBy: userEmail,
    UpdatedBy: userEmail,
  });

  /**
   * Save/submit the user account information.
   *
   * @param accountID Existing user's account ID, or null for new user.
   * @param statusID Account status ID (Active/Inactive)
   * @returns {Promise} true if the submitting a user change was successful.
   */
  submitUserChanges = async (accountID: number | null = null, statusID = 1): Promise<boolean> => {
    const userContext = this.context;
    const userRoleID = this.state.userRole ? parseInt(this.state.userRole.value, 10) : -1;

    if (userRoleID <= 0) {
      throw new Error('Invalid role for submission.');
    }

    if (!userContext.user) {
      throw new Error('No logged-in user found');
    }

    const entitlementsList = [];

    // Create an entitlment list.
    if (userRoleID === 1 || userRoleID === 2) {
      const entitlement = this.createEntitlement(accountID);
      entitlementsList.push(entitlement);
    } else {
      const { selectedProgramIDs } = this.state;
      const programFacilityMap = this.state.programToFacilitiesIDMap;
      const programCorporationMap = this.state.programToCorporationIDMap;
      for (let i = 0; i < selectedProgramIDs.length; i += 1) {
        const programID = selectedProgramIDs[i];
        console.assert(programFacilityMap[programID], 'Error, facilty not found!');
        const entitlement = this.createEntitlement(accountID, programFacilityMap[programID], programID, userContext.user.email, programCorporationMap[programID]);
        entitlementsList.push(entitlement);
      }
    }

    // Create the Facility account data.
    const userAccountData: FacilityAccountData = {
      mode: 'Facility',
      Account: {
        AccountEmail: this.state.email,
        AccountID: accountID,
        UserRoleID: userRoleID,
        FirstName: this.state.firstName,
        LastName: this.state.lastName,
        StatusID: statusID,
        CreatedBy: userContext.user.email,
        UpdatedBy: userContext.user.email,
        Phone: '',
        EmailNotify: true,
        TextNotify: false,
      },
      AccountEntitlement: entitlementsList,
    };

    try {
      const response = await PatientUsersProxy.postPatientAccount([userAccountData]);

      if (!response.success) {
        throw new Error(response.message);
      }

      this.setState((current) => ({
        ...current,
        accountId: response.data ? response.data.toString() : '-1',
      }));

      return true;
    } catch (error) {
      console.error(error);

      if (error instanceof NetworkError) {
        throw new Error(NETWORK_ERROR_MESSAGE);
      }

      throw error;
    }
  };

  afterSaveProcess = (isEditPage: boolean): void => {
    if (
      (FeatureFlagManager.get<boolean>(FeatureFlagName.isCoreMeasuresEnabled) || Utilities.isInternalUser(this.context.user?.accountId))
      && this.isFacilityExecutiveOrFacilityDirectorOrProviderRoleSelected()
    ) {
      this.openAccessTypeSelectionWindow(isEditPage);
    } else {
      this.returnToFacilityUserAccounts();
    }
  };

  openAccessTypeSelectionWindow = (isEditPage: boolean): void => {
    this.setState((current) => ({
      ...current,
      showAccessTypeSelectionWindow: true,
    }));
    if (isEditPage) {
      this.loadAccessTypeOptions();
    }
  };

  closeAccessTypeSelectionWindow = (): void => {
    this.setState((current) => ({
      ...current,
      showAccessTypeSelectionWindow: false,
    }));
  };

  invalidAccessType = (): boolean => !this.isValueSet(this.state.accessType);

  saveAccessTypeSelection = (): void => {
    const accountId = parseInt(this.state.accountId, 10);
    const accessTypeId = parseInt(this.state.accessType ? this.state.accessType.value : '-1', 10);
    UserProxy.postUserAccountAccessType(
      accountId,
      accessTypeId,
      () => {
        this.returnToFacilityUserAccounts();
      },
      (resp) => {
        console.log('Error', resp);
      },
    );
  };
}
