import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as PhoneInput from 'global_elements/Inputs/TextInput/lib/phoneNumber';
import * as EmailInput from 'global_elements/Inputs/TextInput/lib/email';

import './styles.scss';

import DataListsProxy, { DataListType } from 'api/dataLists/dataListsProxy';
import PatientUsersProxy from 'api/patientUsers/patientUsersProxy';
import UserProxy from 'api/user/userProxy';
import Utilities from 'api/lib/Utilities';

import PageLayout from 'global_elements/Layouts/PageLayout';
import InlineText from 'global_elements/Text/InlineText';
import Button from 'global_elements/Button';
import FlexContainer from 'global_elements/Layouts/FlexContainer';
import LabledTextInput from 'global_elements/Inputs/TextInput/LabledTextInput';
import LabledSingleSelect from 'global_elements/Inputs/Dropdown/SingleSelect/LabledSingleSelect';
import PatientInfoCard from 'global_elements/Layouts/Cards/PatientInfoCard';

import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants';
import { TextInputVariant } from 'global_elements/Inputs/TextInput/variants';
import { ButtonVariant } from 'global_elements/Button/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { AllRoutes } from 'constants/routes';
import { PatientAccountSetupState } from 'types/patientAccount';
import { SingleStandardDropdownStyles } from 'global_elements/Inputs/Dropdown/SingleSelect/styles';
import { SelectOption } from 'types/inputProps';
import { PatientAccountData, PatientData } from 'interfaces/patients/patientData';
import { UserContext } from 'context/user';
import { SendEmailInvitationRequest } from 'interfaces/facilityUsers/sendEmailInvitationRequest';
import { NetworkError } from 'api/lib/ApiService/errors';
import { NETWORK_ERROR_MESSAGE } from 'constants/errorMessages';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import UserAccount from '../../../interfaces/users/userAccount';
import TertiaryHeader from '../../../global_elements/Text/TertiaryHeader';
import { CreateAccountRequest } from '../../../interfaces/facilityUsers/createAccountRequest';
import MHODateTime from '../../../domain/dateTime/MHODateTime';
import { UpdateAccountPatientRequest } from '../../../interfaces/patients/updateAccountPatientRequest';
import UserPatientRelationship from '../../../interfaces/users/userPatientRelationship';
import { ListFacility } from '../../../interfaces/dataLists/listFacility';

type uriParams = {
    patient: string;
}

const PatientAccountSetupPage = (): JSX.Element => {
  const history = useHistory();
  const { patient } = useParams<uriParams>();
  const { user } = React.useContext(UserContext);
  const [pageData, setPageData] = React.useState<PatientAccountSetupState>({
    firstName: '',
    lastName: '',
    accountFirstName: '',
    accountLastName: '',
    accountEmail: '',
    accountEmailConfirm: '',
    accountEmailError: false,
    accountEmailConfirmError: false,
    accountEmailIsOpen: true,
    accountEmailIsInUseMessage: null,
    accountPhone: '',
    accountNumber: '',
    medicalRecordNumber: '',
    patientDOB: '',
    patientAdmissionDate: '',
    program: '',
    registrationPIN: '',
    relationship: { label: 'Select an Option', value: '' },
    relationshipOptions: [],
    stateOptions: [],
    facilityOptions: [],
  });
  const [accountSetupError, setAccountSetupError] = React.useState<string | null>(null);
  const isAdmin = user?.role === 'admin' || user?.role === 'super-admin';
  const [userPatientRelationship, setUserPatientRelationship] = React.useState<UserPatientRelationship>();
  const [accountInfo, setAccountInfo] = React.useState<UserAccount>();
  const [accountInfoSelectedRelationship, setAccountInfoSelectedRelationship] = React.useState<SelectOption>();
  const [accountInfoSelectedState, setAccountInfoSelectedState] = React.useState<SelectOption>();
  const [patientInfo, setPatientInfo] = React.useState<UserAccount>();
  const [patientInfoSelectedRelationship, setPatientInfoSelectedRelationship] = React.useState<SelectOption>();
  const [patientInfoSelectedFacility, setPatientInfoSelectedFacility] = React.useState<SelectOption>();
  const [facilities, setFacilities] = React.useState<ListFacility[]>([]);
  const [patientStays, setPatientStays] = React.useState<any[]>([]);

  React.useEffect(() => {
    (async () => {
      const relationshipOptions = await DataListsProxy.getOptionListV2(DataListType.Relationship);
      let newRelationshipOptions: SelectOption[] = [];
      if (relationshipOptions.data) {
        newRelationshipOptions = relationshipOptions.data.map((r) => ({ label: r.lookupDesc, value: r.lookupID.toString() }));
      }
      setPageData((prevState) => ({
        ...prevState,
        relationshipOptions: newRelationshipOptions,
      }));

      const patientId = parseInt(patient, 10);

      let newFacilityOptions: SelectOption[] = [];
      if (isAdmin) {
        const stateOptions = await DataListsProxy.getStateListV2();
        let newStateOptions: SelectOption[] = [];
        if (stateOptions.data) {
          newStateOptions = stateOptions.data.map((s) => ({ label: s.stateDesc, value: s.stateDesc }));
        }
        const facilityOptions = await DataListsProxy.getAllUserFacilitiesV2(patientId);
        if (facilityOptions.data) {
          setFacilities(facilityOptions.data);
          newFacilityOptions = facilityOptions.data.map((f) => ({ label: f.facilityName, value: f.facilityID.toString() }));
        }
        setPageData((prevState) => ({
          ...prevState,
          stateOptions: newStateOptions,
          facilityOptions: newFacilityOptions,
        }));
      }

      const patientInf = await PatientUsersProxy.getPatientV2(patientId);
      if (patientInf.data) {
        const patientData: PatientData = patientInf.data[0];
        setPageData((prevState) => ({
          ...prevState,
          firstName: patientData.patientFirstName,
          lastName: patientData.patientLastName,
          accountNumber: patientData.patientNumber,
          medicalRecordNumber: patientData.medicalRecordNumber,
          patientDOB: patientData.dateOfBirth,
          patientAdmissionDate: patientData.dateAdmitted,
          program: patientData.programName,
          registrationPIN: patientData.registrationPIN?.toString() ?? '',
        }));
      }

      if (isAdmin) {
        const patientAccounts = await UserProxy.getUserPatientRelationshipInfoV2(0, patientId);
        if (patientAccounts.data && patientAccounts.data.length === 1) {
          setUserPatientRelationship(patientAccounts.data[0]);
          const relationship = { label: patientAccounts.data[0].relationship, value: patientAccounts.data[0].relationshipID.toString() };
          setAccountInfoSelectedRelationship(relationship);
          const acct = await UserProxy.getUserAccountInfoV2(patientAccounts.data[0].accountID);
          if (acct.data && acct.data.length > 0) {
            setAccountInfo(acct.data[0]);
            const state = { label: acct.data[0].state, value: acct.data[0].state };
            setAccountInfoSelectedState(state);
          }
          const pat = await UserProxy.getUserPatientInfoV2(patientAccounts.data[0].patientID);
          if (pat.data && pat.data.length > 0) {
            const patient1 = pat.data[0];
            setPatientInfo(patient1);
            const contactRelationship = { label: patient1.contactRelationship, value: patient1.contactRelationshipID.toString() };
            setPatientInfoSelectedRelationship(contactRelationship);
            const facilityOption = newFacilityOptions.find((f) => f.value === patient1.facilityID?.toString());
            if (facilityOption) {
              const facility = { label: facilityOption.label, value: facilityOption.value };
              setPatientInfoSelectedFacility(facility);
            }
            const stays = await PatientUsersProxy.getPatientStaysV2(patientAccounts.data[0].patientID);
            setPatientStays(stays.data);
          } else {
            setPatientInfo({
              accountID: 0,
              accountEmail: '',
              accountGUID: '',
              userRoleID: 0,
              userRoleName: '',
              firstName: '',
              lastName: '',
              phone: '',
              address: '',
              city: '',
              state: '',
              zipCode: '',
              contactFirstName: '',
              contactLastName: '',
              contactEmail: '',
              relationshipID: 0,
              lookupDesc: '',
              contactRelationship: '',
              contactRelationshipID: '',
              contactPhone: '',
              notificationEmail: '',
              patientID: patientAccounts.data[0].patientID ?? 0,
              patientAccountID: acct?.data ? acct.data[0].patientAccountID : 0,
              provider: '',
              providerPhone: '',
              providerEmail: '',
              emailSentDate: new Date(),
              lastLoginDate: new Date(),
              statusID: 0,
              statusName: '',
              adAccount: false,
              emailNotify: false,
              textNotify: false,
              updatedBy: '',
              facilityName: '',
              facilityID: 0,
              facilityPhone: '',
              facilityWebsite: '',
              accessTypeID: 0,
              accessType: '',
            });
          }
        }
      }
    })();
  }, [patient]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.name !== 'accountPhone') {
      setPageData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    } else if (PhoneInput.validPhoneInput(e)) {
      const maskedInput = PhoneInput.maskInputAsPhoneNumber(e);

      if (maskedInput !== undefined) {
        setPageData((prevState) => ({
          ...prevState, accountPhone: maskedInput,
        }));
      }
    }
  };

  const handleRelationshipChange = (option: SelectOption): void => {
    setPageData((prevState) => ({
      ...prevState,
      relationship: option,
    }));
  };

  const doEmailsMatch = (): boolean => (
    Utilities.stringAreEqual(pageData.accountEmail, pageData.accountEmailConfirm)
  );

  const invalidAccountInfo = (): boolean => {
    if (
      pageData.accountFirstName === ''
        || pageData.accountLastName === ''
        || pageData.accountEmail === ''
        || pageData.accountEmailConfirm === ''
        || pageData.relationship.value === ''
        || pageData.accountEmailConfirmError
        || pageData.accountEmailError
        || !pageData.accountEmailIsOpen
        || !!PhoneInput.invalidInput(pageData.accountPhone)
        || !doEmailsMatch()
    ) {
      return true;
    }

    return false;
  };

  const emailErrorText = (): string | undefined => {
    if (pageData.accountEmailError) {
      return 'Enter a Valid Email Address';
    }

    if (!pageData.accountEmailIsOpen) {
      return pageData.accountEmailIsInUseMessage ?? 'Email already in use';
    }

    return undefined;
  };

  const emailConfirmErrorText = (): string | undefined => {
    if (pageData.accountEmailConfirmError) {
      return 'Confirm the Email Address';
    }

    if (!doEmailsMatch()) {
      return 'Emails do not match';
    }

    return undefined;
  };

  const invalidEmailError = (): void => {
    if (pageData.accountEmail === '' || !EmailInput.validEmail(pageData.accountEmail)) {
      setPageData((prevState) => ({
        ...prevState,
        accountEmailError: true,
      }));
    } else {
      UserProxy.getEmailExistsInDatabase(
        pageData.accountEmail,
        (response) => {
          console.log(response);
          setPageData((prevState) => ({
            ...prevState,
            accountEmailIsOpen: false,
            accountEmailIsInUseMessage: response.data,
            accountEmailConfirmError: false,
            accountEmailError: false,
          }));
        },
        (errorResponse) => {
          console.log(errorResponse);
          setPageData((prevState) => ({
            ...prevState,
            accountEmailIsOpen: true,
            accountEmailIsInUseMessage: null,
            accountEmailError: false,
          }));
        },
      );
    }
  };

  const emptyEmailConfirmError = (): void => {
    if (pageData.accountEmailConfirm === '') {
      setPageData((prevState) => ({
        ...prevState,
        accountEmailConfirmError: true,
      }));
    } else {
      setPageData((prevState) => ({
        ...prevState,
        accountEmailConfirmError: false,
      }));
    }
  };

  const createAccountData = async (): Promise<void> => {
    const patientAccountData: PatientAccountData = {
      mode: 'Patient',
      Account: {
        AccountEmail: pageData.accountEmail,
        AccountID: null,
        UserRoleID: 8,
        FirstName: pageData.accountFirstName,
        LastName: pageData.accountLastName,
        Phone: pageData.accountPhone === '' ? null : pageData.accountPhone,
        CreatedBy: user?.email ?? '',
        UpdatedBy: user?.email ?? '',
        StatusID: 1,
        TextNotify: !!pageData.accountPhone,
        EmailNotify: true,
      },
      AccountPatient: [
        {
          AccountPatientID: null,
          AccountID: null,
          CareID: 0,
          PatientID: parseInt(patient, 10),
          RelationshipID: parseInt(pageData.relationship.value, 10),
          StatusID: 1,
          CreatedBy: user?.email ?? '',
          UpdatedBy: user?.email ?? '',
        },
      ],
    };

    try {
      const response = await PatientUsersProxy.postPatientAccount(
        [patientAccountData],
      );

      if (!response.success) {
        throw new Error(response.message)
      }

      setAccountSetupError(null)

      const sendEmailInvitationRequest: SendEmailInvitationRequest = {
        Name: `${patientAccountData.Account.FirstName} ${patientAccountData.Account.LastName}`,
        Email: patientAccountData.Account.AccountEmail,
        Status: '',
      };

      UserProxy.SendEmailInvitation(
        sendEmailInvitationRequest,
        (sendEmailInvitationResponse) => {
          console.log(sendEmailInvitationResponse);
          history.push(`${AllRoutes.PATIENT_ACCOUNTS}/${patient}`);
        },
        (invitationErrorResponse) => {
          console.error(invitationErrorResponse);
          setAccountSetupError('Account created, but email invitation failed.')
        },
      );
    } catch (error: any) {
      if (error instanceof NetworkError) {
        setAccountSetupError(NETWORK_ERROR_MESSAGE)
      } else {
        setAccountSetupError(error.message)
      }
      console.error(error);
    }
  };

  const cancel = (): void => {
    history.push(`${AllRoutes.PATIENT_ACCOUNTS}/${patient}`);
  };

  const handleAccountInfoInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setAccountInfo((prevState) => prevState && ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const saveAccountInfo = async (): Promise<undefined> => {
    if (!accountInfo) {
      return;
    }
    const data: CreateAccountRequest = {
      AccountEmail: accountInfo.accountEmail,
      AccountID: accountInfo.accountID,
      UserRoleID: accountInfo.userRoleID,
      FirstName: accountInfo.firstName,
      LastName: accountInfo.lastName,
      StatusID: accountInfo.statusID,
      UpdatedBy: user?.email ?? '',
      Phone: accountInfo.phone,
      Address: accountInfo.address,
      RelationshipID: parseInt(accountInfoSelectedRelationship?.value ?? '0', 10),
      City: accountInfo.city,
      State: accountInfoSelectedState?.value,
      ZipCode: accountInfo.zipCode,
      NotificationEmail: accountInfo.notificationEmail,
      EmailNotify: accountInfo.emailNotify,
      TextNotify: accountInfo.textNotify,
      EmailSentDate: accountInfo.emailSentDate ? new MHODateTime(accountInfo.emailSentDate).getDateTimeForPost() : null,
      LastLoginDate: accountInfo.lastLoginDate ? new MHODateTime(accountInfo.lastLoginDate).getDateTimeForPost() : null,
    };
    await UserProxy.postUserAccountInfoV2([data]);
    const relData = {
      accountID: userPatientRelationship?.accountID,
      accountPatientID: userPatientRelationship?.accountPatientID,
      careID: userPatientRelationship?.careID,
      patientID: userPatientRelationship?.patientID,
      relationshipID: parseInt(accountInfoSelectedRelationship?.value ?? '0', 10),
      statusID: userPatientRelationship?.statusID,
      UpdatedBy: user?.email ?? '',
    };
    await UserProxy.saveUserPatientRelationshipInfoV2([relData]);
  };

  const handlePatientInfoInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPatientInfo((prevState) => prevState && ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFacilitySelected = (option: SelectOption): void => {
    setPatientInfoSelectedFacility(option);
    const facility = facilities.find((f) => f.facilityID.toString() === option.value);
    setPatientInfo((prevState) => prevState && ({
      ...prevState,
      facilityPhone: facility?.phoneNumber ?? '',
      facilityWebsite: facility?.website ?? '',
    }));
  };

  const savePatientInfo = async (): Promise<undefined> => {
    if (!patientInfo) {
      return;
    }
    const data: UpdateAccountPatientRequest = {
      patientAccountID: patientInfo.patientAccountID,
      patientID: patientInfo.patientID,
      contactFirstName: patientInfo.contactFirstName,
      contactLastName: patientInfo.contactLastName,
      contactRelationshipID: parseInt(patientInfoSelectedRelationship?.value ?? '0', 10),
      contactPhone: patientInfo.contactPhone,
      contactEmail: patientInfo.contactEmail,
      facilityID: parseInt(patientInfoSelectedFacility?.value ?? '0', 10),
      statusID: 1,
      UpdatedBy: user?.email ?? '',
    };
    await UserProxy.postUserPatientInfoV2([data]);
  };

  const phoneError = (phone: string): string | undefined => {
    if (!PhoneInput.validPhoneNumber(phone)) {
      return 'Invalid Phone Number';
    }
    return undefined;
  };

  const disableSaveAccountInfo = (): boolean => (
    !accountInfo
      || accountInfo.firstName === ''
      || accountInfo.lastName === ''
      || accountInfoSelectedRelationship?.value === ''
      || !!phoneError(accountInfo.phone)
      || !!EmailInput.inputError(accountInfo.notificationEmail)
  );

  const disableSavePatientInfo = (): boolean => (
    !patientInfo
      || !!phoneError(patientInfo.contactPhone)
      || !!EmailInput.inputError(patientInfo.contactEmail)
  );

  return (
    <PageLayout layout={PageLayoutVariant.PADDED} testText="Patient Account Setup Page">
      <FlexContainer
        display={DisplayVariant.FLEX_COL}
        align={AlignVariant.START}
        justify={JustifyVariant.START}
        extraClasses="patient-account-setup-content"
      >
        <PatientInfoCard
          patientID={patient}
          patientFirstName={pageData.firstName}
          patientLastName={pageData.lastName}
          patientDOB={pageData.patientDOB}
          patientAdmissionDate={pageData.patientAdmissionDate}
          patientAccountNumber={pageData.accountNumber}
          patientProgram={pageData.program}
          patientMedicalRecordNumber={pageData.medicalRecordNumber}
          patientRegistrationPIN={pageData.registrationPIN}
        />

        <Tabs>
          <TabList>
            <Tab>
              <InlineText
                text="Account Set-up"
                fontColor={FontColors.DARK}
                fontSize={FontSizes.LARGE}
              />
            </Tab>
            {isAdmin && accountInfo && patientInfo && (
              <>
                <Tab>
                  <InlineText
                    text="Account Information"
                    fontColor={FontColors.DARK}
                    fontSize={FontSizes.LARGE}
                  />
                </Tab>
                <Tab>
                  <InlineText
                    text="Patient Information"
                    fontColor={FontColors.DARK}
                    fontSize={FontSizes.LARGE}
                  />
                </Tab>
              </>
            )}
          </TabList>
          <TabPanel>
            <form>
              <FlexContainer
                display={DisplayVariant.FLEX_ROW}
                align={AlignVariant.START}
                justify={JustifyVariant.START}
                extraClasses="patient-account-setup-content__first-row"
              >
                <LabledTextInput
                  label="First Name"
                  name="accountFirstName"
                  value={pageData.accountFirstName}
                  onChange={handleInputChange}
                  type="text"
                  variant={TextInputVariant.PRIMARY}
                  autoFocus
                />
                <LabledTextInput
                  label="Last Name"
                  name="accountLastName"
                  value={pageData.accountLastName}
                  onChange={handleInputChange}
                  type="text"
                  variant={TextInputVariant.PRIMARY}
                />
              </FlexContainer>
              <FlexContainer
                display={DisplayVariant.FLEX_ROW}
                align={AlignVariant.START}
                justify={JustifyVariant.START}
                extraClasses="patient-account-setup-content__second-row"
              >
                <LabledTextInput
                  label="Email"
                  name="accountEmail"
                  value={pageData.accountEmail}
                  onChange={handleInputChange}
                  type="email"
                  variant={TextInputVariant.PRIMARY}
                  onBlur={invalidEmailError}
                  error={emailErrorText()}
                />
                <LabledTextInput
                  label="Confirm Email"
                  name="accountEmailConfirm"
                  value={pageData.accountEmailConfirm}
                  onChange={handleInputChange}
                  type="email"
                  variant={TextInputVariant.PRIMARY}
                  onBlur={emptyEmailConfirmError}
                  error={emailConfirmErrorText()}
                />
              </FlexContainer>
              <FlexContainer
                display={DisplayVariant.FLEX_ROW}
                align={AlignVariant.START}
                justify={JustifyVariant.START}
                extraClasses="patient-account-setup-content__third-row"
              >
                {pageData.relationshipOptions.length === 0
                  && (
                    <LabledSingleSelect
                      label="Relationship"
                      styles={SingleStandardDropdownStyles}
                      options={[]}
                      defaultValue={{ label: 'Loading...', value: '' }}
                      value={{ label: 'Loading...', value: '' }}
                      onSelection={handleRelationshipChange}
                    />
                  )}
                {pageData.relationshipOptions.length > 0
                  && (
                    <LabledSingleSelect
                      label="Relationship"
                      styles={SingleStandardDropdownStyles}
                      options={pageData.relationshipOptions}
                      defaultValue={pageData.relationshipOptions[0]}
                      value={pageData.relationship}
                      onSelection={handleRelationshipChange}
                    />
                  )}
                <LabledTextInput
                  label="Phone (Optional)"
                  placeholder="(123) 456-7890"
                  name="accountPhone"
                  value={pageData.accountPhone}
                  onChange={handleInputChange}
                  type="text"
                  variant={TextInputVariant.PRIMARY}
                  error={PhoneInput.invalidInput(pageData.accountPhone)}
                />
              </FlexContainer>
            </form>
            <FlexContainer
              display={DisplayVariant.FLEX_ROW}
              justify={JustifyVariant.END}
              align={AlignVariant.START}
              extraClasses="button-container"
            >
              <Button
                variant={ButtonVariant.SECONDARY}
                onClick={cancel}
              >
                <InlineText
                  text="Cancel"
                  fontColor={FontColors.BACKGROUND}
                  fontSize={FontSizes.REGULAR}
                  bold
                />
              </Button>
              <Button
                variant={ButtonVariant.PRIMARY}
                onClick={createAccountData}
                disabled={invalidAccountInfo()}
              >
                <InlineText
                  text="Register"
                  fontColor={FontColors.BACKGROUND}
                  fontSize={FontSizes.REGULAR}
                  bold
                />
              </Button>
            </FlexContainer>
            {accountSetupError
              && (
                <InlineText
                  text={accountSetupError}
                  fontColor={FontColors.HIGH_PRIORITY}
                  fontSize={FontSizes.LARGE}
                  bold
                />
              )}
          </TabPanel>
          {isAdmin && accountInfo && patientInfo && (
            <>
              <TabPanel>
                <FlexContainer
                  display={DisplayVariant.FLEX_COL}
                  align={AlignVariant.START}
                  justify={JustifyVariant.START}
                  extraClasses="patient-account-settings-page__account__email"
                >
                  <TertiaryHeader text="Email Address/Account ID" fontColor={FontColors.PRIMARY} marginBottomPx={9} />
                  <FlexContainer
                    display={DisplayVariant.FLEX_ROW}
                    align={AlignVariant.CENTER}
                    justify={JustifyVariant.SPACE_BETWEEN}
                    extraClasses="patient-account-settings-page__account__email__content"
                  >
                    <InlineText text={accountInfo ? accountInfo.accountEmail : ''} fontColor={FontColors.LIGHT_GRAY} fontSize={FontSizes.REGULAR} />
                  </FlexContainer>
                </FlexContainer>
                <TertiaryHeader text="Account Holder Information" fontColor={FontColors.PRIMARY} marginBottomPx={9} />
                <FlexContainer
                  display={DisplayVariant.FLEX_ROW}
                  align={AlignVariant.START}
                  justify={JustifyVariant.SPACE_BETWEEN}
                  extraClasses="patient-account-settings-page__account__information__first-row"
                >
                  <LabledTextInput
                    variant={TextInputVariant.PRIMARY}
                    label="First Name *"
                    type="text"
                    autoFocus
                    name="firstName"
                    value={accountInfo?.firstName}
                    onChange={handleAccountInfoInputChange}
                  />
                  <LabledTextInput
                    variant={TextInputVariant.PRIMARY}
                    label="Last Name *"
                    type="text"
                    name="lastName"
                    value={accountInfo?.lastName}
                    onChange={handleAccountInfoInputChange}
                  />
                  {!accountInfoSelectedRelationship && (
                    <LabledSingleSelect
                      label="Relationship to Patient *"
                      styles={SingleStandardDropdownStyles}
                      options={[]}
                      defaultValue={{ label: 'Loading...', value: '' }}
                      value={{ label: 'Loading...', value: '' }}
                      onSelection={setAccountInfoSelectedRelationship}
                    />
                  )}
                  {accountInfoSelectedRelationship && (
                    <LabledSingleSelect
                      label="Relationship to Patient *"
                      styles={SingleStandardDropdownStyles}
                      options={pageData.relationshipOptions}
                      defaultValue={accountInfoSelectedRelationship ?? null}
                      value={accountInfoSelectedRelationship}
                      onSelection={setAccountInfoSelectedRelationship}
                    />
                  )}
                </FlexContainer>
                <FlexContainer
                  display={DisplayVariant.FLEX_ROW}
                  align={AlignVariant.START}
                  justify={JustifyVariant.SPACE_BETWEEN}
                  extraClasses="patient-account-settings-page__account__information__second-row"
                >
                  <LabledTextInput
                    variant={TextInputVariant.PRIMARY}
                    label="Address"
                    type="text"
                    name="address"
                    value={accountInfo?.address}
                    onChange={handleAccountInfoInputChange}
                  />
                  <LabledTextInput
                    variant={TextInputVariant.PRIMARY}
                    label="City"
                    type="text"
                    name="city"
                    value={accountInfo?.city}
                    onChange={handleAccountInfoInputChange}
                  />
                  {!accountInfoSelectedState && (
                    <LabledSingleSelect
                      label="State"
                      styles={SingleStandardDropdownStyles}
                      options={[]}
                      defaultValue={{ label: 'Loading...', value: '' }}
                      value={{ label: 'Loading...', value: '' }}
                      onSelection={setAccountInfoSelectedState}
                    />
                  )}
                  {accountInfoSelectedState && (
                    <LabledSingleSelect
                      label="State"
                      styles={SingleStandardDropdownStyles}
                      options={pageData.stateOptions}
                      defaultValue={accountInfoSelectedState ?? { label: 'Select a State', value: '' }}
                      value={accountInfoSelectedState}
                      onSelection={setAccountInfoSelectedState}
                    />
                  )}
                  <LabledTextInput
                    variant={TextInputVariant.PRIMARY}
                    label="ZIP Code"
                    type="text"
                    name="zipCode"
                    value={accountInfo?.zipCode}
                    onChange={handleAccountInfoInputChange}
                  />
                </FlexContainer>
                <FlexContainer
                  display={DisplayVariant.FLEX_ROW}
                  align={AlignVariant.START}
                  justify={JustifyVariant.SPACE_BETWEEN}
                  extraClasses="patient-account-settings-page__account__information__third-row"
                >
                  <LabledTextInput
                    variant={TextInputVariant.PRIMARY}
                    label="Phone"
                    type="text"
                    name="phone"
                    value={accountInfo?.phone}
                    onChange={handleAccountInfoInputChange}
                    error={phoneError(accountInfo?.phone)}
                  />
                  <LabledTextInput
                    variant={TextInputVariant.PRIMARY}
                    label="Email for Notifications"
                    type="email"
                    name="notificationEmail"
                    value={accountInfo?.notificationEmail}
                    onChange={handleAccountInfoInputChange}
                    error={EmailInput.inputError(accountInfo?.notificationEmail)}
                  />
                  <FlexContainer
                    display={DisplayVariant.FLEX_COL}
                    align={AlignVariant.START}
                    justify={JustifyVariant.START}
                    extraClasses="patient-account-settings-page__account__information__third-row__notifications"
                  >
                    <TertiaryHeader text="Notifications" fontColor={FontColors.SECONDARY} marginBottomPx={12} />
                    <FlexContainer
                      display={DisplayVariant.FLEX_ROW}
                      align={AlignVariant.CENTER}
                      justify={JustifyVariant.START}
                      extraClasses="patient-account-settings-page__account__information__third-row__notifications__toggles"
                    >
                      <span>
                        <InlineText text="Email" fontColor={FontColors.LIGHT_GRAY} fontSize={FontSizes.LARGE} bold />
                        <label className="toggle">
                          <input
                            onKeyUp={
                              (e) => {
                                if (e.key === 'Enter') {
                                  setAccountInfo({ ...accountInfo, emailNotify: !accountInfo.emailNotify });
                                }
                              }
                            }
                            name="emailNotify"
                            type="checkbox"
                            checked={accountInfo.emailNotify}
                            onChange={(e) => { setAccountInfo({ ...accountInfo, emailNotify: e.target.checked }); }}
                          />
                          <span className="slider" />
                        </label>
                      </span>
                    </FlexContainer>
                  </FlexContainer>
                </FlexContainer>
                <FlexContainer
                  display={DisplayVariant.FLEX_ROW}
                  align={AlignVariant.START}
                  justify={JustifyVariant.END}
                  extraClasses="patient-account-settings-page__patient__buttons"
                >
                  <Button
                    variant={ButtonVariant.SECONDARY}
                    onClick={cancel}
                  >
                    <InlineText text="Cancel" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
                  </Button>
                  <Button
                    variant={ButtonVariant.PRIMARY}
                    onClick={saveAccountInfo}
                    disabled={disableSaveAccountInfo()}
                  >
                    <InlineText text="Save Changes" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
                  </Button>
                </FlexContainer>
              </TabPanel>
              <TabPanel>
                <FlexContainer
                  display={DisplayVariant.FLEX_COL}
                  align={AlignVariant.START}
                  justify={JustifyVariant.START}
                  extraClasses="patient-account-settings-page__patient__emergency-contact"
                >
                  <TertiaryHeader text="Emergency Contact" fontColor={FontColors.PRIMARY} marginBottomPx={9} />
                  <FlexContainer
                    display={DisplayVariant.FLEX_ROW}
                    align={AlignVariant.START}
                    justify={JustifyVariant.SPACE_BETWEEN}
                    extraClasses="patient-account-settings-page__patient__emergency-contact__first-row"
                  >
                    <LabledTextInput
                      variant={TextInputVariant.PRIMARY}
                      label="First Name"
                      type="text"
                      name="contactFirstName"
                      value={patientInfo?.contactFirstName}
                      onChange={handlePatientInfoInputChange}
                      autoFocus
                    />
                    <LabledTextInput
                      variant={TextInputVariant.PRIMARY}
                      label="Last Name"
                      type="text"
                      name="contactLastName"
                      value={patientInfo?.contactLastName}
                      onChange={handlePatientInfoInputChange}
                    />
                    <LabledSingleSelect
                      label="Relationship to Patient *"
                      styles={SingleStandardDropdownStyles}
                      options={pageData.relationshipOptions}
                      defaultValue={patientInfoSelectedRelationship ?? null}
                      value={patientInfoSelectedRelationship}
                      onSelection={setPatientInfoSelectedRelationship}
                    />
                  </FlexContainer>
                  <FlexContainer
                    display={DisplayVariant.FLEX_ROW}
                    align={AlignVariant.START}
                    justify={JustifyVariant.SPACE_BETWEEN}
                    extraClasses="patient-account-settings-page__patient__emergency-contact__second-row"
                  >
                    <LabledTextInput
                      variant={TextInputVariant.PRIMARY}
                      label="Phone Number"
                      type="text"
                      name="contactPhone"
                      value={patientInfo?.contactPhone}
                      onChange={handlePatientInfoInputChange}
                      error={phoneError(patientInfo?.contactPhone)}
                    />
                    <LabledTextInput
                      variant={TextInputVariant.PRIMARY}
                      label="Email Address"
                      type="email"
                      name="contactEmail"
                      value={patientInfo?.contactEmail}
                      onChange={handlePatientInfoInputChange}
                      error={EmailInput.inputError(patientInfo?.contactEmail)}
                    />
                  </FlexContainer>
                </FlexContainer>
                <FlexContainer
                  display={DisplayVariant.FLEX_COL}
                  align={AlignVariant.START}
                  justify={JustifyVariant.START}
                  extraClasses="patient-account-settings-page__patient__emergency-contact"
                >
                  <TertiaryHeader text="Preferred Facility" fontColor={FontColors.PRIMARY} marginBottomPx={9} />
                  <FlexContainer
                    display={DisplayVariant.FLEX_ROW}
                    align={AlignVariant.START}
                    justify={JustifyVariant.SPACE_BETWEEN}
                    extraClasses="patient-account-settings-page__patient__emergency-contact__first-row"
                  >
                    <LabledSingleSelect
                      styles={SingleStandardDropdownStyles}
                      label="Facility"
                      options={pageData.facilityOptions}
                      defaultValue={patientInfoSelectedFacility ?? null}
                      value={patientInfoSelectedFacility}
                      onSelection={handleFacilitySelected}
                    />
                    <LabledTextInput
                      variant={TextInputVariant.PRIMARY}
                      label="Phone Number"
                      type="text"
                      disabled={1}
                      name="facilityPhone"
                      value={patientInfo?.facilityPhone}
                      onChange={handlePatientInfoInputChange}
                    />
                    <LabledTextInput
                      variant={TextInputVariant.PRIMARY}
                      label="Website Address"
                      type="text"
                      disabled={1}
                      name="facilityWebsite"
                      value={patientInfo?.facilityWebsite}
                      onChange={handlePatientInfoInputChange}
                    />
                  </FlexContainer>
                </FlexContainer>
                <FlexContainer
                  display={DisplayVariant.FLEX_ROW}
                  align={AlignVariant.START}
                  justify={JustifyVariant.END}
                  extraClasses="patient-account-settings-page__patient__buttons"
                >
                  <Button
                    variant={ButtonVariant.SECONDARY}
                    onClick={cancel}
                  >
                    <InlineText text="Cancel" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
                  </Button>
                  <Button
                    variant={ButtonVariant.PRIMARY}
                    onClick={savePatientInfo}
                    disabled={disableSavePatientInfo()}
                  >
                    <InlineText text="Save Changes" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
                  </Button>
                </FlexContainer>
                <FlexContainer
                  display={DisplayVariant.FLEX_COL}
                  align={AlignVariant.START}
                  justify={JustifyVariant.START}
                  extraClasses="patient-account-settings-page__patient__treatments"
                >
                  <TertiaryHeader text="Previous Inpatient/Outpatient Treatments" fontColor={FontColors.PRIMARY} marginBottomPx={9} />
                  <div className="patient-stays">
                    {patientStays && patientStays.length && (
                      patientStays.map((stay: any) => (
                        <FlexContainer
                          display={DisplayVariant.FLEX_ROW}
                          align={AlignVariant.CENTER}
                          justify={JustifyVariant.START}
                          extraClasses="patient-stays--container"
                        >
                          <InlineText text={`${stay.dateAdmitted ? new MHODateTime(stay.dateAdmitted).getFormattedCalendarDate() : 'N/A'} - ${stay.dateDischarged ? new MHODateTime(stay.dateDischarged).getFormattedCalendarDate() : 'N/A'}`} fontColor={FontColors.LIGHT_GRAY} fontSize={FontSizes.REGULAR} />
                          <InlineText text={stay.facilityName} fontColor={FontColors.LIGHT_GRAY} fontSize={FontSizes.REGULAR} />
                        </FlexContainer>
                      ))
                    )}
                  </div>
                </FlexContainer>
              </TabPanel>
            </>
          )}
        </Tabs>
      </FlexContainer>
    </PageLayout>
  );
};

export default PatientAccountSetupPage;
