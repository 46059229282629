import { useEffect, useState, useContext, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { UserContext } from 'context/user'

import './styles.scss';

import PatientUsersProxy from 'api/patientUsers/patientUsersProxy'
import FlexContainer from 'global_elements/Layouts/FlexContainer'
import { DisplayVariant, AlignVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants'
import PageLayout from 'global_elements/Layouts/PageLayout'
import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants'
import InlineText from 'global_elements/Text/InlineText'
import { FontColors, FontSizes } from 'global_elements/Text/variants'
import PrimaryHeader from '../../../global_elements/Text/PrimaryHeader'
import SecondaryHeader from '../../../global_elements/Text/SecondaryHeader'
import Button from '../../../global_elements/Button'
import { ButtonVariant } from '../../../global_elements/Button/variants'
import { PatientRoutes } from '../../../constants/routes'

interface UriParams {
  accountDeleteLinkHash: string
}

export function AccountDeleteConfirmPage(): JSX.Element {
  const { accountDeleteLinkHash } = useParams<UriParams>();
  const [loading, setLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>();
  const { Logout } = useContext(UserContext);
  const history = useHistory();
  const cancelButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    PatientUsersProxy.VerifyDeletePatientAccountLink(accountDeleteLinkHash)
      .then((r) => {
        if (!r.success) {
          setLoading(false);
          setErrorMessage(r.message);
        } else {
          setLoading(false);
          setErrorMessage(undefined);
        }
      });
  }, []);

  useEffect(() => {
    cancelButtonRef.current?.focus();
  }, [loading]);

  const CancelClicked = (): void => {
    history.push(PatientRoutes.DASHBOARD);
  };

  const OkClicked = async (): Promise<void> => {
    try {
      await PatientUsersProxy.SaveDeletePatientAccountConfirmation(accountDeleteLinkHash);
    } catch (error: any) {
      console.error(error);
    }
    Logout();
    history.push(PatientRoutes.DASHBOARD);
  };

  if (loading || errorMessage) {
    return (
      <PageLayout
        layout={PageLayoutVariant.PADDED}
        testText="Account Delete Confirm Page"
        hideHeader
      >
        <FlexContainer
          display={DisplayVariant.FLEX_COL}
          align={AlignVariant.START}
          justify={JustifyVariant.START}
          extraClasses="account-delete-confirm-page"
        >
          {loading && (
            <InlineText
              text="Loading..."
              fontColor={FontColors.PRIMARY}
              fontSize={FontSizes.SUPER_EXTRA_LARGE}
            />
          )}
          {errorMessage && (
            <>
              <InlineText
                text={errorMessage}
                fontColor={FontColors.PRIMARY}
                fontSize={FontSizes.SUPER_EXTRA_LARGE}
                extraClasses="error-message"
              />
              <FlexContainer
                display={DisplayVariant.FLEX_ROW}
                align={AlignVariant.CENTER}
                justify={JustifyVariant.END}
                extraClasses="account-delete-confirm-page__button-container"
              >
                <Button
                  variant={ButtonVariant.PRIMARY}
                  submit
                  onClick={CancelClicked}
                >
                  <InlineText
                    text="Login"
                    fontColor={FontColors.BACKGROUND}
                    fontSize={FontSizes.REGULAR}
                    bold
                  />
                </Button>
              </FlexContainer>
            </>
          )}
        </FlexContainer>
      </PageLayout>
    )
  }

  return (
    <PageLayout
      layout={PageLayoutVariant.PADDED}
      testText="Account Delete Confirm Page"
      hideHeader
      extraClasses="account-delete-confirm-page-layout"
    >
      <FlexContainer
        display={DisplayVariant.FLEX_COL}
        align={AlignVariant.START}
        justify={JustifyVariant.START}
        extraClasses="account-delete-confirm-page"
      >
        <FlexContainer
          display={DisplayVariant.FLEX_COL}
          align={AlignVariant.START}
          justify={JustifyVariant.START}
        >
          <PrimaryHeader text="Delete Account" fontColor={FontColors.PRIMARY} marginBottomPx={16} />
          <SecondaryHeader text="Click Delete below to complete account removal. This action is irreversible." fontColor={FontColors.SECONDARY} marginBottomPx={16} />
        </FlexContainer>
        <FlexContainer
          display={DisplayVariant.FLEX_ROW}
          align={AlignVariant.CENTER}
          justify={JustifyVariant.END}
          extraClasses="account-delete-confirm-page__button-container"
        >
          <Button
            ref={cancelButtonRef}
            variant={ButtonVariant.SECONDARY}
            submit
            onClick={CancelClicked}
            testid="delete-account-cancel-button"
          >
            <InlineText
              text="Cancel"
              fontColor={FontColors.BACKGROUND}
              fontSize={FontSizes.REGULAR}
              bold
            />
          </Button>
          <Button
            variant={ButtonVariant.HIGH_PRIORITY}
            submit
            onClick={OkClicked}
            testid="delete-account-confirm-button"
          >
            <InlineText
              text="Delete"
              fontColor={FontColors.BACKGROUND}
              fontSize={FontSizes.REGULAR}
              bold
            />
          </Button>
        </FlexContainer>
      </FlexContainer>
    </PageLayout>
  )
}
