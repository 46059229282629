import * as React from 'react';
import { useHistory } from 'react-router-dom';

import * as EmailInput from 'global_elements/Inputs/TextInput/lib/email';
import Button from 'global_elements/Button';
import MHODateTime from 'domain/dateTime/MHODateTime';
import FlexContainer from 'global_elements/Layouts/FlexContainer';
import Paragraph from 'global_elements/Text/Paragraph';
import { ButtonVariant } from 'global_elements/Button/variants';
import { DisplayVariant, JustifyVariant, AlignVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import { ProviderAssessmentCardProps } from 'types/cardProps';
import { ProviderRoutes } from 'constants/routes';
import { ReactComponent as X } from 'icons/mho-x-icon.svg';

import './styles.scss';
import PopupWindow from 'global_elements/Layouts/PopupWindow';
import { useState } from 'react';
import TertiaryHeader from 'global_elements/Text/TertiaryHeader';
import InlineText from 'global_elements/Text/InlineText';
import LabledTextInput from 'global_elements/Inputs/TextInput/LabledTextInput';
import { TextInputVariant } from 'global_elements/Inputs/TextInput/variants';
import AssessmentProxy from 'api/assessments/assessmentProxy';
import { UserContext } from 'context/user';

const ProviderAssessmentCard = (props: ProviderAssessmentCardProps): JSX.Element => {
  const history = useHistory();
  const { user } = React.useContext(UserContext);
  const searchParams = new URLSearchParams();
  const [popupWindowShown, setPopupWindowShown] = useState<boolean>(false);
  const [assessmentLinkEmail, setAssessmentLinkEmail] = useState<string>('');
  if (props?.completed) {
    searchParams.append('date', props.completed);
    searchParams.append('awid', props.appliedWhenID.toString());
  }
  const resultsClickUrl = `${ProviderRoutes.ASSESSMENT_RESULTS}/${props.careID}/${props.instrumentTypeID}/${props.patientID}`;
  const startClickUrl = `${ProviderRoutes.ASSESSMENT}/${props.id}/${props.instrumentTypeID}/${props.appliedWhenID}/${props.patientID}`;

  const hasBeenSentBefore = (): boolean => props.assessmentLinkEmail !== null && props.assessmentLinkSentDate !== null;
  const invalidEmail = (): boolean => !EmailInput.validEmail(assessmentLinkEmail);
  const frozenClass = props.submissionFrozen ? 'frozen' : '';

  const openEmailModal = (): void => {
    setPopupWindowShown(true);
  };

  const closeEmailModal = (): void => {
    setPopupWindowShown(false);
  };

  const getTimeString = (): string => {
    if (props.assessmentLinkSentDate) {
      return new MHODateTime(props.assessmentLinkSentDate).getJSDate().toLocaleString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: '2-digit' });
    }
    return '';
  };

  const sendAssessmentLinkEmail = (): void => {
    AssessmentProxy.sendAssessmentLink({
      accountID: user ? parseInt(user.accountId, 10) : -1,
      patientID: props.patientID,
      instrumentTypeID: props.instrumentTypeID,
      assessmentNumber: parseInt(props.assessmentNumber, 10),
      appliedWhenID: props.appliedWhenID,
      assessmentLinkEmail,
    }).then(() => {
      setAssessmentLinkEmail('');
      closeEmailModal();
      window.location.reload();
    });
  };

  return (
    <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.START} align={AlignVariant.END} extraClasses={`provider-assessment-card-wrapper ${frozenClass}`}>
      {/* Do not show the "Applied When" label when it's a core measures assessment */}
      {!props.isCoreMeasures && (
        <FlexContainer
          display={DisplayVariant.FLEX_COL}
          justify={JustifyVariant.CENTER}
          align={AlignVariant.CENTER}
          extraClasses={`provider-assessment-card-type --${props.appliedWhen.toString().toLowerCase()}`}
        >
          <Paragraph text={props.appliedWhen} fontColor={FontColors.BACKGROUND} fontSize={FontSizes.EXTRA_SMALL} bold />
        </FlexContainer>
      )}

      <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.SPACE_BETWEEN} align={AlignVariant.START} extraClasses="provider-assessment-card-content">
        {/* ****history fontsize */}
        <Paragraph text={`${props.instrumentType} (${props.assessmentNumber})`} fontColor={FontColors.SECONDARY} fontSize={FontSizes.LARGE} extraClasses="--limit-width" bold />
        <Paragraph text={props.title} fontColor={FontColors.DARK} fontSize={FontSizes.REGULAR} />
        <FlexContainer
          display={DisplayVariant.FLEX_ROW}
          justify={props.completed ? JustifyVariant.SPACE_BETWEEN : JustifyVariant.END}
          align={AlignVariant.START}
          extraClasses="provider-assessment-card-bottom"
        >
          <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.SPACE_BETWEEN} align={AlignVariant.START} extraClasses="provider-assessment-card-cta">
            <div className="completed-when">
              {props.completed && (
                <Paragraph
                  text={`Completed: ${new MHODateTime(props.completed).getFormattedCalendarDate()}`}
                  fontColor={FontColors.DARK}
                  fontSize={FontSizes.SMALL}
                  extraClasses={`${frozenClass}`}
                  bold
                />
              )}
            </div>
            {props.availableForPatient === 1 && !props.isCoreMeasures && (
              <Button variant={ButtonVariant.INVISIBLE} onClick={openEmailModal} testid="send-resend-button">
                <Paragraph text={hasBeenSentBefore() ? 'Resend' : 'Send'} fontColor={FontColors.SECONDARY} fontSize={FontSizes.SMALL} underlined />
              </Button>
            )}
            {props.ctas.map((cta) => (
              <Button
                variant={ButtonVariant.INVISIBLE}
                onClick={() => {
                  if (cta === 'Results') {
                    history.push({
                      pathname: resultsClickUrl,
                      search: searchParams.toString(),
                    });
                  } else {
                    history.push(startClickUrl, {
                      sequenceNumber: props.sequenceNumber ? props.sequenceNumber : 1,
                      loadAnswersFlag: props.loadAnswersFlag,
                      dischargeDisp: props.dischargeDisp,
                    });
                  }
                }}
              >
                {/* ****history accessment card fontsize */}
                <Paragraph text={cta} fontColor={FontColors.SECONDARY} fontSize={FontSizes.REGULAR} underlined />
              </Button>
            ))}
          </FlexContainer>
        </FlexContainer>
        {popupWindowShown && (
          <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.CENTER} align={AlignVariant.CENTER} extraClasses="popup-window-wrapper" testid="popup-window-wrapper">
            <PopupWindow>
              <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.START} align={AlignVariant.START} extraClasses="popup-window-content">
                <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.SPACE_BETWEEN} align={AlignVariant.CENTER} extraClasses="popup-window-header">
                  <TertiaryHeader text={`Send Assessment Link for ${props.instrumentType} (${props.assessmentNumber})`} fontColor={FontColors.PRIMARY} />
                  <Button
                    variant={ButtonVariant.INVISIBLE}
                    onClick={(e) => {
                      e?.preventDefault();
                      e?.stopPropagation();
                      closeEmailModal();
                    }}
                  >
                    <X />
                  </Button>
                </FlexContainer>
                {hasBeenSentBefore() && (
                  <Paragraph
                    text={`Email previously sent to ${props.assessmentLinkEmail} on ${getTimeString()} (CT).`}
                    fontColor={FontColors.DARK}
                    fontSize={FontSizes.REGULAR}
                    extraClasses="previously-sent-text"
                    testId="previously-sent-text"
                  />
                )}
                <form className="send-assessment-link" onSubmit={sendAssessmentLinkEmail}>
                  <LabledTextInput
                    label="Email"
                    placeholder="Enter the email to send an assessment link to..."
                    name="email"
                    value={assessmentLinkEmail}
                    onChange={(e) => setAssessmentLinkEmail(e.target.value)}
                    type="email"
                    variant={TextInputVariant.PRIMARY}
                    autoFocus
                    testid="email-input"
                  />
                  <Button variant={ButtonVariant.PRIMARY} disabled={invalidEmail()} submit onClick={sendAssessmentLinkEmail} testid="submit-send-resend-button">
                    <InlineText text={hasBeenSentBefore() ? 'Resend' : 'Send'} fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
                  </Button>
                </form>
              </FlexContainer>
            </PopupWindow>
          </FlexContainer>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

export default React.memo(ProviderAssessmentCard);
