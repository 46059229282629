import * as React from 'react';
import { useHistory } from 'react-router-dom';
import * as EmailInput from 'global_elements/Inputs/TextInput/lib/email';
import * as DateInput from 'global_elements/Inputs/TextInput/lib/date';

import './styles.scss';

import PageLayout from 'global_elements/Layouts/PageLayout';
import FlexContainer from 'global_elements/Layouts/FlexContainer';
import Button from 'global_elements/Button';
import InlineText from 'global_elements/Text/InlineText';
import SecondaryHeader from 'global_elements/Text/SecondaryHeader';
import LabledTextInput from 'global_elements/Inputs/TextInput/LabledTextInput';
import LabeledDateInput from 'global_elements/Inputs/LabeledDateInput';

import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants';
import { DisplayVariant, JustifyVariant, AlignVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { ButtonVariant } from 'global_elements/Button/variants';
import { FontSizes, FontColors } from 'global_elements/Text/variants';
import { TextInputVariant } from 'global_elements/Inputs/TextInput/variants';
import { AnonymousRoutes } from 'constants/routes';
import UserProxy from 'api/user/userProxy';
import Utilities from 'api/lib/Utilities';
import { useEffect } from 'react';

type RegisterObject = {
    pin: string;
    dob: string;
    email: string;
    emailConfirm: string;
}

const RegisterByPINPage = (): JSX.Element => {
  const [showConfirmationText, setShowConfirmationText] = React.useState<boolean>(false);
  const confirmationText = 'A confirmation email has been sent to the email address provided above.';
  const [emailInUseError, setEmailInUseError] = React.useState<boolean>(false);
  const [invalidPinError, setInvalidPinError] = React.useState<boolean>(false);
  const emailInUseErrorTextTop = 'The email address you entered is already associated with an account.';
  const emailInUseErrorTextBottom = 'Please enter a different email address or';
  const [registrationInfo, setRegistrationInfo] = React.useState<RegisterObject>({
    pin: '',
    dob: '',
    email: '',
    emailConfirm: '',
  });
  const [errorMessage, setErrorMessage] = React.useState<string>();

  useEffect(() => {
    // No-op
  }, [showConfirmationText,
    confirmationText,
    emailInUseError,
    invalidPinError,
    emailInUseErrorTextTop,
    emailInUseErrorTextBottom,
    registrationInfo.email,
    registrationInfo.emailConfirm,
  ]);

  const history = useHistory();

  const returnToLogin = (): void => {
    history.push(AnonymousRoutes.LOGIN);
  };

  const doEmailsMatch = (): boolean => (
    Utilities.stringAreEqual(registrationInfo.email, registrationInfo.emailConfirm)
  );

  const checkEmailExists = (): void => {
    UserProxy.getAnonymousToken(
      (resp: any) => {
        // NOTE: This is confusing, but the way the API handles this
        // is to return a 200 (success) response if the email exists, which
        // should show a validation error in the UI.
        // If the email doesn't exist, an error response is returned,
        // which means the UI is OK to proceed because the email
        // is not in use.
        UserProxy.verifyPinEmail(
          resp.data,
          registrationInfo.email,
          (response) => {
            console.log(response);
            setEmailInUseError(true);
          },
          (errorResponse) => {
            console.log(errorResponse);
            setEmailInUseError(false);
          },
        );
      },
      (err: any) => console.log(err),
    );
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if ((e.target.name === 'email' || e.target.name === 'emailConfirm')) {
      setRegistrationInfo((prevState) => (
        { ...prevState, [e.target.name]: e.target.value }
      ));
    } else if (e.target.name === 'pin') {
      if (new RegExp(/([0-9])/g).test(e.target.value.slice(-1)) || e.target.value === '') {
        if (e.target.value.length <= 8) {
          setRegistrationInfo((prevState) => (
            { ...prevState, [e.target.name]: e.target.value }
          ));
        }
      }
    }
  };

  const handleDobChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (DateInput.validDateInput(e)) {
      let maskedOutput = DateInput.maskInputAsDate(e);
      if (e.target.value.length < registrationInfo.dob.length) {
        maskedOutput = e.target.value;
      }
      setRegistrationInfo((prevState) => (
        { ...prevState, [e.target.name]: maskedOutput }
      ));
    }
  };

  const validPinString = (pin: string): boolean => (
    new RegExp(/^\d{8}$/).test(pin)
  );

  const pinError = (): string | undefined => {
    if (registrationInfo.pin !== '' && !validPinString(registrationInfo.pin)) {
      return 'Invalid PIN';
    }

    return undefined;
  };

  const emailError = (): string | undefined => {
    if (emailInUseError) {
      return ' ';
    }

    if (registrationInfo.email !== '' && !EmailInput.validEmail(registrationInfo.email)) {
      return 'Invalid Email Address';
    }

    return undefined;
  };

  const emailMismatchError = (): string | undefined => {
    if (!doEmailsMatch()) {
      return 'Emails do not match';
    }

    return undefined;
  };

  const invalidRegistrationData = (): boolean => (
    registrationInfo.pin === ''
      || registrationInfo.dob === ''
      || registrationInfo.email === ''
      || registrationInfo.emailConfirm === ''
      || !!pinError()
      || !!DateInput.inputError(registrationInfo.dob)
      || !!emailError()
      || !!emailMismatchError()
      || emailInUseError
  );

  const disableRegistration = (): boolean => (
    invalidRegistrationData()
  );

  const getDobString = (): string => {
    const dob = registrationInfo.dob.split('/');
    return `${dob[2]}-${dob[0]}-${dob[1]}`;
  };

  const sendPinAndDobValidation = (resp: any): void => {
    UserProxy.sendInviteIfPinIsValid(
      resp.data,
      registrationInfo.pin,
      getDobString(),
      registrationInfo.email,
    ).then((r) => {
      if (r.success && r.data && r.data.indexOf('1') >= 0) {
        setShowConfirmationText(true);
        setInvalidPinError(false);
        setErrorMessage(undefined);
      } else {
        setErrorMessage(r.message);
      }
    }).catch((r) => {
      console.log(r);
    });
  };

  const handleSubmit = (): void => {
    UserProxy.getAnonymousToken(
      (resp: any) => sendPinAndDobValidation(resp),
      (err: any) => console.log(err),
    );
  };

  const disableEvent = (e: any): boolean => {
    e.preventDefault();
    return false;
  };

  return (
    <PageLayout
      layout={PageLayoutVariant.LOGGED_OUT}
      testText="Register By PIN Page"
    >
      <FlexContainer
        display={DisplayVariant.FLEX_COL}
        align={AlignVariant.CENTER}
        justify={JustifyVariant.CENTER}
        extraClasses="register-by-pin-content__inputs"
      >
        <SecondaryHeader text="Registration Verification" fontColor={FontColors.PRIMARY} />
        <LabledTextInput
          variant={TextInputVariant.PRIMARY}
          label="Registration PIN (provided by your facility)*"
          type="text"
          name="pin"
          value={registrationInfo.pin}
          onChange={handleInputChange}
          error={pinError()}
        />
        <LabeledDateInput
          variant={TextInputVariant.PRIMARY}
          label="Patient Date of Birth (mm/dd/yyyy)*"
          type="text"
          name="dob"
          value={registrationInfo.dob}
          onChange={handleDobChange}
          error={DateInput.inputError(registrationInfo.dob)}
        />
        <LabledTextInput
          variant={TextInputVariant.PRIMARY}
          label="Email address*"
          type="email"
          name="email"
          value={registrationInfo.email}
          onChange={handleInputChange}
          onBlur={() => { checkEmailExists(); doEmailsMatch(); }}
          onCopy={disableEvent}
          onCut={disableEvent}
          error={emailError()}
        />
        {emailInUseError
            && (
              <FlexContainer
                display={DisplayVariant.FLEX_COL}
                align={AlignVariant.CENTER}
                justify={JustifyVariant.CENTER}
                extraClasses="register-by-pin-content__inputs__email-error"
              >
                <InlineText
                  text={emailInUseErrorTextTop}
                  fontColor={FontColors.HIGH_PRIORITY}
                  fontSize={FontSizes.LARGE}
                />
                <InlineText
                  text={emailInUseErrorTextBottom}
                  fontColor={FontColors.HIGH_PRIORITY}
                  fontSize={FontSizes.LARGE}
                />
                <Button
                  variant={ButtonVariant.INVISIBLE}
                  onClick={returnToLogin}
                >
                  <InlineText
                    text="Log In"
                    fontColor={FontColors.PRIMARY}
                    fontSize={FontSizes.LARGE}
                    bold
                    underlined
                    italic
                  />
                </Button>
              </FlexContainer>
            )}
        <LabledTextInput
          variant={TextInputVariant.PRIMARY}
          label="Verify Email address*"
          type="email"
          name="emailConfirm"
          value={registrationInfo.emailConfirm}
          onChange={handleInputChange}
          onBlur={() => { doEmailsMatch(); }}
          onPaste={disableEvent}
          error={emailMismatchError()}
        />
        <Button
          variant={ButtonVariant.DARK}
          onClick={handleSubmit}
          disabled={disableRegistration()}
        >
          <InlineText
            text="Send Verification Email"
            fontColor={FontColors.BACKGROUND}
            fontSize={FontSizes.LARGE}
            bold
          />
        </Button>

        {invalidPinError
        && (
          <InlineText
            text="The values entered do not match our records.  Please try again."
            fontColor={FontColors.HIGH_PRIORITY}
            fontSize={FontSizes.LARGE}
            bold
          />
        )}
        {errorMessage
          && (
            <InlineText
              text={errorMessage}
              fontColor={FontColors.HIGH_PRIORITY}
              fontSize={FontSizes.LARGE}
              bold
            />
          )}
      </FlexContainer>
      <FlexContainer
        display={DisplayVariant.FLEX_COL}
        align={AlignVariant.CENTER}
        justify={JustifyVariant.CENTER}
        extraClasses="register-by-pin-content__text"
      >
        {showConfirmationText
          && (
            <>
              <InlineText
                text={confirmationText}
                fontColor={FontColors.LOW_PRIORITY}
                fontSize={FontSizes.LARGE}
                bold
              />
              <InlineText
                text="Didn't get an Email?"
                fontColor={FontColors.DARK}
                fontSize={FontSizes.REGULAR}
              />
              <Button
                variant={ButtonVariant.INVISIBLE}
                onClick={() => { console.log(registrationInfo); setShowConfirmationText(true); }}
              >
                <InlineText
                  text="Resend Verification Email"
                  fontColor={FontColors.DARK}
                  fontSize={FontSizes.LARGE}
                  bold
                  underlined
                />
              </Button>
            </>
          )}
      </FlexContainer>
    </PageLayout>
  );
};

export default RegisterByPINPage;
