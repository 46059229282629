import './styles.scss';
import React, { useEffect, useState } from 'react';
import PageLayout from 'global_elements/Layouts/PageLayout';
import FlexContainer from 'global_elements/Layouts/FlexContainer';
import SecondaryHeader from 'global_elements/Text/SecondaryHeader';
import Paragraph from 'global_elements/Text/Paragraph';
import InlineText from 'global_elements/Text/InlineText';
import { ReactComponent as DocumentIcon } from 'icons/mho-document-icon.svg';
import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import UserProxy from 'api/user/userProxy';
import AttachmentsProxy from 'api/attachments/AttachmentsProxy';
import { AllRoutes } from 'constants/routes';
import AccordionList from 'global_elements/Layouts/AccordionList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faAngleDoubleRight, faFolder, faFolderPlus, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faFile, faFileExcel, faFilePdf, faFileVideo, faFileWord } from '@fortawesome/free-regular-svg-icons';
import { useHistory } from 'react-router-dom';
import { UserContext } from 'context/user';
import Utilities from 'api/lib/Utilities';
import FeatureFlagManager from '../../../config/featureFlags/FeatureFlagManager';
import { FeatureFlagName } from '../../../config/featureFlags';
import { AttachmentFolderContentsModel, AttachmentFolderModel, AttachmentModel } from '../../../interfaces/attachments/AttachmentFolderContentsModel';
import { AccessType } from '../../../types/facilityUserAccount';

interface HelpLink {
  faqText: string;
  helpFAQDesc: string;
  helpFAQLabel: string;
  helpFAQName: string;
  helpFAQType: string;
  helpFAQTypeID: number;
  helpFileHash: string;
}

const HelpPage = (): JSX.Element => {
  const { user } = React.useContext(UserContext);
  const isAdmin = user?.role === 'admin' || user?.role === 'super-admin';
  const [loading] = useState<boolean>(false);
  const [helpLinks, setHelpLinks] = useState<HelpLink[]>([]);
  const [faqs, setFaqs] = useState<Map<number, any[]>>();
  const isCoreMeasuresEnabled = FeatureFlagManager.get<boolean>(FeatureFlagName.isCoreMeasuresEnabled) || Utilities.isInternalUser(user?.accountId);
  const history = useHistory();
  const [coreMeasuresFolderId, setCoreMeasuresFolderId] = useState<string>();
  const [coreMeasuresFolderContents, setCoreMeasuresFolderContents] = useState<AttachmentFolderContentsModel>();
  const [coreMeasuresFolderBreadcrumbs, setCoreMeasuresFolderBreadcrumbs] = useState<AttachmentFolderModel[]>([]);
  const isCoreMeasuresHelpVisible = isCoreMeasuresEnabled && user && (user.accessType === AccessType.ALL_ACCESS || user.accessType === AccessType.CORE_MEASURES);
  const [dropzoneHoverClass, setDropzoneHoverClass] = useState<string>('');

  useEffect(() => {
    UserProxy.getHelpFileLinks()
      .then((resp) => {
        const items = resp.data || [];
        if (Array.isArray(items)) {
          const helpItems = items.filter((i: any) => i.helpFAQTypeID === 1);
          setHelpLinks(helpItems);

          const groups = new Map<number, any[]>();
          items
            .filter((i: any) => i.helpFAQTypeID === 2)
            .forEach((faq) => {
              if (!groups.has(faq.documentGroupID)) {
                groups.set(faq.documentGroupID, []);
              }
              const grp = groups.get(faq.documentGroupID);
              if (grp) {
                grp.push(faq);
              }
            });
          setFaqs(groups);
        }
      })
      .catch((e) => console.log(e));
    if (isCoreMeasuresHelpVisible) {
      AttachmentsProxy.getCoreMeasuresHelpFolderContents()
        .then((c) => {
          if (c.data) {
            setCoreMeasuresFolderBreadcrumbs([{ attachmentFolderId: AttachmentsProxy.CoreMeasuresHelpFolderId, name: 'CM Help' }]);
            setCoreMeasuresFolderId(AttachmentsProxy.CoreMeasuresHelpFolderId);
            setCoreMeasuresFolderContents(c.data);
          }
        })
        .catch((e) => console.log(e));
    }
  }, []);

  const documentCard = (): JSX.Element => (
    <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="help-page__documents">
      <SecondaryHeader text="Quick Start" fontColor={FontColors.PRIMARY} marginBottomPx={16} />
      <Paragraph text="These documents contain helpful information for getting started. Click a link below to open the file." fontColor={FontColors.DARK} fontSize={FontSizes.REGULAR} />
      <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.SPACE_BETWEEN} extraClasses="help-page__documents__links" wrap>
        {helpLinks.map((document) => (
          <a className="external-link" key={document.helpFileHash} href={`${AllRoutes.HELP_DOWNLOAD}/${document.helpFileHash}`}>
            <DocumentIcon />
            <InlineText text={document.helpFAQLabel} fontColor={FontColors.SECONDARY} fontSize={FontSizes.REGULAR} bold />
          </a>
        ))}
      </FlexContainer>
    </FlexContainer>
  );

  const LoadFolderContents = (attachmentFolderId: string): void => {
    AttachmentsProxy.getFolderContents(attachmentFolderId)
      .then((c) => {
        if (c.data) {
          setCoreMeasuresFolderId(attachmentFolderId);
          setCoreMeasuresFolderContents(c.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const LoadFolder = (folder: AttachmentFolderModel): void => {
    let newBreadcrumbs = coreMeasuresFolderBreadcrumbs;
    for (let i = 0; i < coreMeasuresFolderBreadcrumbs.length; i += 1) {
      if (coreMeasuresFolderBreadcrumbs[i].attachmentFolderId === folder.attachmentFolderId) {
        newBreadcrumbs = coreMeasuresFolderBreadcrumbs.slice(0, i);
        break;
      }
    }
    newBreadcrumbs.push(folder);
    setCoreMeasuresFolderBreadcrumbs(newBreadcrumbs);
    LoadFolderContents(folder.attachmentFolderId);
  };

  const NewFolder = (): void => {
    const name = window.prompt('New folder name?', 'New folder');
    if (!name) {
      return;
    }
    AttachmentsProxy.saveFolder(undefined, coreMeasuresFolderId!, name).then(() => {
      LoadFolderContents(coreMeasuresFolderId!);
    });
  };

  const EditFolder = (folder: AttachmentFolderModel): void => {
    const newName = window.prompt('New name?', folder.name);
    if (!newName) {
      return;
    }
    AttachmentsProxy.saveFolder(folder.attachmentFolderId, coreMeasuresFolderId!, newName).then(() => {
      LoadFolderContents(coreMeasuresFolderId!);
    });
  };

  const DeleteFolder = (folder: AttachmentFolderModel): void => {
    if (!window.confirm(`Are you sure you want to delete, '${folder.name}'?`)) {
      return;
    }
    AttachmentsProxy.deleteFolder(folder.attachmentFolderId).then(() => {
      LoadFolderContents(coreMeasuresFolderId!);
    });
  };

  const GetFileIcon = (contentType: string): IconDefinition => {
    const lowerContentType = contentType.toLowerCase();
    switch (lowerContentType) {
      case 'application/msword':
        return faFileWord;
      case 'application/pdf':
        return faFilePdf;
      case 'application/vnd.ms-excel':
        return faFileExcel;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return faFileWord;
      case 'video/mp4':
        return faFileVideo;
      default:
        return faFile;
    }
  };

  const OpenAttachment = (file: AttachmentModel): void => {
    if (file.contentType === 'application/pdf') {
      history.push(`${AllRoutes.ATTACHMENT_DOWNLOAD}/${file.attachmentId}`);
    } else {
      AttachmentsProxy.download(file.attachmentId)
        .then((response) => {
          if (response.data) {
            const dl = new File([response.data], file.name, { type: file.contentType });
            const dlUrl = URL.createObjectURL(dl);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.href = dlUrl;
            a.download = file.name;
            a.target = '_self';
            a.click();
            URL.revokeObjectURL(dlUrl);
          }
        })
        .catch((e) => console.log(e));
    }
  };

  const EditAttachment = (file: AttachmentModel): void => {
    const newName = window.prompt('New name?', file.name);
    if (!newName) {
      return;
    }
    AttachmentsProxy.saveAttachment(file.attachmentId, newName).then(() => {
      LoadFolderContents(coreMeasuresFolderId!);
    });
  };

  const DeleteAttachment = (file: AttachmentModel): void => {
    if (!window.confirm(`Are you sure you want to delete, '${file.name}'?`)) {
      return;
    }
    AttachmentsProxy.deleteAttachment(file.attachmentId).then(() => {
      LoadFolderContents(coreMeasuresFolderId!);
    });
  };

  const UploadFiles = (files: FileList | null): void => {
    if (!files || files.length < 1) {
      return;
    }
    AttachmentsProxy.upload(coreMeasuresFolderId!, files).then(() => {
      LoadFolderContents(coreMeasuresFolderId!);
    });
  };

  return (
    <PageLayout
      layout={PageLayoutVariant.PADDED}
      bannerCard={documentCard()}
      testText="Help Page"
      loadingTitle={loading ? 'Help' : undefined}
      loadingText={loading ? 'Loading Help Resources...' : undefined}
    >
      {isCoreMeasuresHelpVisible && coreMeasuresFolderContents && (
        <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="core-measures-help-container">
          <SecondaryHeader text="Core Measures Help" fontColor={FontColors.PRIMARY} marginBottomPx={16} />
          <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="core-measures-help-breadcrumbs">
            {coreMeasuresFolderBreadcrumbs.length > 1
              && coreMeasuresFolderBreadcrumbs.map((bc, idx) => (
                <>
                  {idx < coreMeasuresFolderBreadcrumbs.length - 1 && (
                    <>
                      <a
                        href="https://validurl.com"
                        onClick={(e) => {
                          e.preventDefault();
                          LoadFolder(bc);
                        }}
                      >
                        {bc.name}
                      </a>
                      <FontAwesomeIcon icon={faAngleDoubleRight} className="core-measures-help-breadcrumbs-divider" />
                    </>
                  )}
                  {idx === coreMeasuresFolderBreadcrumbs.length - 1 && <span>{bc.name}</span>}
                </>
              ))}
          </FlexContainer>
          {isAdmin && (
            <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="core-measures-help-admin">
              <button
                type="button"
                aria-label="New Folder"
                onClick={() => {
                  NewFolder();
                }}
              >
                <FontAwesomeIcon icon={faFolderPlus} />
                &nbsp;
                <span>New Folder</span>
              </button>
            </FlexContainer>
          )}
          <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} wrap extraClasses="core-measures-help-folders-container">
            {coreMeasuresFolderContents.folders.map((f) => (
              <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="core-measures-help-folder">
                <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="core-measures-help-folder-name">
                  <span>
                    <a
                      href="https://validurl.com"
                      onClick={(e) => {
                        e.preventDefault();
                        LoadFolder(f);
                      }}
                    >
                      <FontAwesomeIcon icon={faFolder} style={{ paddingRight: '5px' }} />
                      <span>{f.name}</span>
                    </a>
                    {isAdmin && (
                      <span style={{ whiteSpace: 'nowrap' }}>
                        <FontAwesomeIcon
                          icon={faPencil}
                          style={{ paddingLeft: '5px', cursor: 'pointer' }}
                          onClick={() => {
                            EditFolder(f);
                          }}
                        />
                        <FontAwesomeIcon
                          icon={faTrash}
                          style={{ paddingLeft: '3px', cursor: 'pointer' }}
                          onClick={() => {
                            DeleteFolder(f);
                          }}
                        />
                      </span>
                    )}
                  </span>
                </FlexContainer>
              </FlexContainer>
            ))}
            {coreMeasuresFolderContents.files.map((f) => (
              <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="core-measures-help-file">
                <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="core-measures-help-file-name">
                  <span>
                    <a
                      href="https://validurl.com"
                      onClick={(e) => {
                        e.preventDefault();
                        OpenAttachment(f);
                      }}
                    >
                      <FontAwesomeIcon icon={GetFileIcon(f.contentType)} style={{ paddingRight: '5px' }} />
                      <span>{f.name}</span>
                    </a>
                    {isAdmin && (
                      <span style={{ whiteSpace: 'nowrap' }}>
                        <FontAwesomeIcon
                          icon={faPencil}
                          style={{ paddingLeft: '5px', cursor: 'pointer' }}
                          onClick={() => {
                            EditAttachment(f);
                          }}
                        />
                        <FontAwesomeIcon
                          icon={faTrash}
                          style={{ paddingLeft: '3px', cursor: 'pointer' }}
                          onClick={() => {
                            DeleteAttachment(f);
                          }}
                        />
                      </span>
                    )}
                  </span>
                </FlexContainer>
              </FlexContainer>
            ))}
          </FlexContainer>
          {isAdmin && (
            <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses={`core-measures-help-dropzone ${dropzoneHoverClass}`}>
              <span>Drop Files Here (or click)</span>
              <input
                type="file"
                multiple
                onChange={(e) => {
                  UploadFiles(e.target.files);
                  e.currentTarget.value = '';
                  setDropzoneHoverClass('');
                }}
                onDragEnter={() => setDropzoneHoverClass('core-measures-help-dropzone-hover')}
                onDragOver={() => setDropzoneHoverClass('core-measures-help-dropzone-hover')}
                onDragLeave={() => setDropzoneHoverClass('')}
              />
            </FlexContainer>
          )}
        </FlexContainer>
      )}
      {faqs && faqs.size > 0 ? <AccordionList title="FAQs" listItems={faqs} /> : <></>}
    </PageLayout>
  );
};

export default HelpPage;
