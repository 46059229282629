import AssessmentProxy from 'api/assessments/assessmentProxy'
import PatientUsersProxy from 'api/patientUsers/patientUsersProxy'
import { AllRoutes } from 'constants/routes'
import FlexContainer from 'global_elements/Layouts/FlexContainer'
import { DisplayVariant, AlignVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants'
import PageLayout from 'global_elements/Layouts/PageLayout'
import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants'
import InlineText from 'global_elements/Text/InlineText'
import { FontColors, FontSizes } from 'global_elements/Text/variants'
import { PatientData } from 'interfaces/patients/patientData'
import { AssessmentSurvey, PatientAssessmentData } from 'pages/shared/assessments/assessment'
import { useContext, useEffect, useState } from 'react'
import { Prompt, useHistory, useLocation } from 'react-router-dom'
import * as Survey from 'survey-react'
import './styles.scss'
import { UserContext } from 'context/user'
import { AssessmentResult } from 'api/assessments/types'
import { AssessmentData } from 'interfaces/assessments/assessmentData'
import { StorageConstants } from 'constants/storageConstants'
import { getAssessmentResultsDescriptor } from '../assessments/shared'

const DaisyChainAssessmentsPage = (): JSX.Element => {
  const history = useHistory()
  const [loading, setLoading] = useState<boolean>(true)
  const [assessments, setAssessments] = useState<AssessmentData[]>([])
  const [assessmentIndex, setAssessmentIndex] = useState<number>(0)
  const [patient, setPatient] = useState<PatientAssessmentData | null>(null)
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const { userAccount } = useContext(UserContext);

  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const startAssessmentParam = searchParams.get('startAssessment')

  if (!userAccount) {
    history.push(AllRoutes.LOGIN)
  }

  const advanceAssessmentOrFinish = (delayMs = 1000): void => {
    if (assessmentIndex < assessments.length - 1) {
      setAssessmentIndex(assessmentIndex + 1)
      window.scrollTo(0, 0)
    } else {
      // Brief delay to show thank you message
      const completeDelay = delayMs === 0 || !delayMs ? 5000 : delayMs;
      setTimeout(() => {
        history.push(AllRoutes.DASHBOARD)
      }, completeDelay)
    }
  }

  const overrideSuccessMessage = (): void => {
    const successMessage = document.querySelector('.sv_save_msg_success');

    // TODO: Make sure this is styled correctly
    if (successMessage) {
      successMessage.innerHTML = `
        <div class="sv_save_msg_success">
          <span>Assessment submitted. Continue to the new one.</span>
          <button class="sv_btn sv_btn_clear">Continue</button>
        </div>
      `;
    }

    // Add event listener to the button
    const continueButton = document.querySelector('.sv_save_msg_success button');
    if (continueButton) {
      continueButton.addEventListener('click', () => {
        advanceAssessmentOrFinish(0)
      })
    }
  }

  const onCancel = (): void => {
    sessionStorage.setItem(StorageConstants.DaisyChainCancelled, 'true')
    advanceAssessmentOrFinish(10)
  }

  useEffect(() => {
    if (!userAccount) {
      return
    }

    PatientUsersProxy.getPatientAssessments(userAccount.patientID).then((results: AssessmentData[]) => {
      // Get todo assessments
      let patientAssessments = results.filter((assessment) => assessment.sortOrder === 1 || assessment.sortOrder === 2)
      if (startAssessmentParam) {
        const startAssessmentNumber = parseInt(startAssessmentParam, 10)
        const startIndex = patientAssessments.findIndex((assessment) => assessment.assessmentNumber === startAssessmentNumber)
        if (startIndex > -1) {
          const startAssessment = patientAssessments.slice(startIndex, startIndex + 1);
          patientAssessments = patientAssessments.filter((assessment) => assessment.assessmentNumber !== startAssessmentNumber)
          patientAssessments = startAssessment.concat(patientAssessments)
        }
      }

      if (!patientAssessments.length) {
        console.error('No assessments found for patient')
        onCancel()
        return
      }

      setAssessments(patientAssessments)

      setLoading(false)
    })
  }, [])

  useEffect(() => {
    if (!userAccount) {
      return
    }

    PatientUsersProxy.getPatient(
      userAccount.patientID,
      (response: any) => {
        if (response.data) {
          const patientData: PatientData = response.data[0]
          setPatient({
            accountID: patientData.accountID!,
            patientID: patientData.patientID!,
            programCode: patientData.programCode,
            patientNumber: patientData.patientNumber,
            careID: patientData.careID,
            treatmentTypeID: patientData.treatmentTypeID,
            corporationID: patientData.corporationID,
            populationTypeID: patientData.populationTypeID,
          })
        }
      },
      (errorResponse: any) => {
        console.log(errorResponse)
      },
    )
  }, [])

  const handlePageClosePrompt = ((event: any): any => {
    event.preventDefault();
    event.returnValue = '';
  })

  useEffect(() => {
    window.addEventListener('beforeunload', handlePageClosePrompt)
    return () => {
      setIsDirty(false);
      window.removeEventListener('beforeunload', handlePageClosePrompt)
    }
  }, [])

  const onComplete = async (survey: Survey.Model, options: any): Promise<void> => {
    options.showDataSaving()
    setIsDirty(false)
    const results = Object.entries(survey.data)

    if (!userAccount) {
      return
    }

    // Construct the data format for the API.
    const resultsList: AssessmentResult[] = []
    for (let i = 0; i < results.length; i += 1) {
      const resultRow = {
        patientID: userAccount.patientID,
        assessmentNumber: assessments[assessmentIndex].assessmentNumber,
        variableName: results[i][0],
        dataValue: String(results[i][1]),
      };
      resultsList.push(resultRow)
    }

    try {
      await AssessmentProxy.saveAssessmentForm(resultsList)
      options.showDataSavingSuccess()

      if (patient?.careID) {
        // When the survey is complete, redirect to the results page.
        const assessmentResultsDescriptor = getAssessmentResultsDescriptor(survey, {
          assessmentNumber: assessments[assessmentIndex].assessmentNumber.toString(),
          assessmentPatientId: assessments[assessmentIndex].patientID,
          careId: patient.careID,
          daisyChain: assessmentIndex < assessments.length - 1,
          instrumentTypeId: assessments[assessmentIndex].instrumentTypeID,
        })

        // Add delay to allow the DB procedures to complete and avoid showing no results
        if (assessmentResultsDescriptor) {
          setTimeout(() => {
            history.push(assessmentResultsDescriptor);
          }, 5000);
        } else {
          // Brief delay to show thank you message
          setTimeout(() => {
            if (assessmentIndex < assessments.length - 1 && assessmentResultsDescriptor) {
              overrideSuccessMessage()
            } else if (!assessmentResultsDescriptor) {
              advanceAssessmentOrFinish(10);
            } else {
              history.push(AllRoutes.DASHBOARD)
            }
          }, 1000)
        }
      }
    } catch (error) {
      console.log(error)

      // Slight delay to show loading message briefly
      setTimeout(() => {
        options.showDataSavingError()
      }, 500);

      throw error
    }
  }

  if (loading || !patient) {
    return (
      <PageLayout
        layout={PageLayoutVariant.PADDED}
        testText="Daisy Chain Assessments Page"
        loadingText={loading ? 'Loading...' : undefined}
        hideHeader
      >
        <FlexContainer
          display={DisplayVariant.FLEX_COL}
          align={AlignVariant.START}
          justify={JustifyVariant.START}
        >
          <InlineText
            text="Loading Assessments..."
            fontColor={FontColors.PRIMARY}
            fontSize={FontSizes.SUPER_EXTRA_LARGE}
          />
        </FlexContainer>
      </PageLayout>
    )
  }

  return (
    <>
      <AssessmentSurvey
        assessmentNumber={assessments[assessmentIndex].assessmentNumber}
        appliedWhenID={assessments[assessmentIndex].appliedWhenID}
        patientAssessmentData={patient}
        onCompleteCallback={onComplete}
        setIsDirtyCallback={setIsDirty}
        onCancelCallback={onCancel}
        isPatient
        extraClasses="daisy-chain-assessments-page"
        showExtremeMessages
      />
      <Prompt when={isDirty} message="Are you sure you want to close the form? Incomplete assessments will be lost." />
    </>
  )
}

export default DaisyChainAssessmentsPage
