import * as React from 'react';

import '../styles.scss';

import { HeadingTextProps } from 'types/textProps';

const PrimaryHeader = (props: HeadingTextProps): JSX.Element => {
  const classes = `text ${props.fontColor}${props.italic ? ' --italic' : ''}${props.underlined ? ' --underlined' : ''} + ' ' + ${props.extraClasses}` ?? '';

  const style = {
    marginTop: props.marginTopPx,
    marginRight: props.marginRightPx,
    marginBottom: props.marginBottomPx,
    marginLeft: props.marginLeftPx,
  };

  return (
    <h1 className={classes} style={style}>
      {props.text}
    </h1>
  );
};

export default React.memo(PrimaryHeader, (prevProps: Readonly<HeadingTextProps>, nextProps: Readonly<HeadingTextProps>) => prevProps.text === nextProps.text);
