/* eslint-disable */
import { ReactComponent as X } from 'icons/mho-x-icon.svg';
import './styles.scss';
import React, { useEffect, useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import DataListsProxy, { DataListType } from 'api/dataLists/dataListsProxy';
import facilityUsersProxy from 'api/facilityUsers/facilityUsersProxy';
import PageLayout from 'global_elements/Layouts/PageLayout';
import PrimaryHeader from 'global_elements/Text/PrimaryHeader';
import InlineText from 'global_elements/Text/InlineText';
import Button from 'global_elements/Button';
import FacilityUserAccountsTable from 'global_elements/Layouts/DataTables/FacilityUserAccountsTable';
import FlexContainer from 'global_elements/Layouts/FlexContainer';
import LabledTextInput from 'global_elements/Inputs/TextInput/LabledTextInput';
import LabledSingleSelect from 'global_elements/Inputs/Dropdown/SingleSelect/LabledSingleSelect';
import LabledAsyncSingleSelect from 'global_elements/Inputs/Dropdown/SingleSelect/LabledSingleSelect/asyncLabledSingleSelct';
import { Roles } from 'constants/roles';
import { SelectOption } from 'types/inputProps';
import { SingleStandardDropdownStyles } from 'global_elements/Inputs/Dropdown/SingleSelect/styles';
import { FacilityUserSearchState } from 'types/facilityUserAccount';
import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants';
import { TextInputVariant } from 'global_elements/Inputs/TextInput/variants';
import { ButtonVariant } from 'global_elements/Button/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { UserContext } from 'context/user';
import { AllRoutes } from 'constants/routes';
import { ActiveFacilityContext } from 'context/activeFacility';
import { FacilityUserDataRow } from 'types/tableProps';
import { FacilityUserSearchCriteria } from 'interfaces/facilityUsers/facilityUserSearchResult';
import { NarrowRoleOptions } from 'global_elements/Inputs/Dropdown/lib/dropDownLists';
import { FacilityUserTools } from 'lib/Facility/tools';
import Utilities from 'api/lib/Utilities';
import PopupWindow from '../../../global_elements/Layouts/PopupWindow';
import TertiaryHeader from '../../../global_elements/Text/TertiaryHeader';
import UserProxy from '../../../api/user/userProxy';
import FeatureFlagManager from '../../../config/featureFlags/FeatureFlagManager';
import { FeatureFlagName } from '../../../config/featureFlags';

const FacilityUserAccountsPage = (): JSX.Element => {
  const { facility } = useContext(ActiveFacilityContext);
  const { user, facilityUserSearchQuery, SetFacilityUserSearchQuery } = useContext(UserContext);
  const history = useHistory();
  const inputRef = React.useRef<any>();
  const [isCoreMeasuresEnabled] = useState(FeatureFlagManager.get<boolean>(FeatureFlagName.isCoreMeasuresEnabled) || Utilities.isInternalUser(user?.accountId));
  const [searchState, setSearchState] = useState<FacilityUserSearchState>(
    facilityUserSearchQuery || {
      availableCorporations: [],
      availableFacilities: [],
      firstName: '',
      lastName: '',
      email: '',
      corporation: null,
      facility: facility,
      role: { label: 'All Roles', value: Roles.NONE },
      roleOptions: [],
      data: [],
      firstSearch: false,
      currentPage: 1,
      accessType: null,

      showAccessTypeSelectionWindow: false,
      accessTypeSelectionWindowAccountId: -1,
      accessTypeSelectionWindowAccessTypeOptions: [],
      accessTypeSelectionWindowSelectedAccessType: null,
    }
  );

  useEffect(() => {
    if (facility.value !== '') {
      setSearchState((prevState) => ({
        ...prevState,
        facility: facility,
        firstSearch: false,
        data: [],
        FacilityUserAccountsTable: null,
      }));
      inputRef.current?.focus();
    }
  }, [facility]);

  React.useEffect(() => {
    if (searchState.data.length > 0 || searchState.firstSearch) {
      Utilities.setCellTitles();
    }

    if (searchState.firstSearch) {
      SetFacilityUserSearchQuery(searchState);
    } else {
      SetFacilityUserSearchQuery(null);
    }
  }, [searchState]);

  /**
   * Retrieve the User Roles for the async dropdown.
   *
   * @returns {Promise} a list of all user roles.
   */
  const loadRoleOptionsPromise = (): Promise<SelectOption[]> =>
    new Promise<SelectOption[]>((resolve, reject) => {
      DataListsProxy.getOptionList(
        DataListType.UserRole,
        (response) => {
          let userRoleOptions: SelectOption[] = [];

          if (response.data) {
            response.data.forEach((option) => {
              userRoleOptions.push({ label: option.lookupDesc, value: option.lookupID.toString() });
            });
          }

          const roleOptions = NarrowRoleOptions(user?.role, userRoleOptions, true);
          resolve(roleOptions);
        },
        (errorResponse) => {
          console.log(errorResponse);
          reject(errorResponse);
        }
      );
    });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleRoleSelectChange = (option: SelectOption): void => {
    setSearchState((prevState) => ({
      ...prevState,
      role: option,
    }));
  };

  const handleFacilitySelectChange = (option: SelectOption): void => {
    setSearchState((prevState) => ({
      ...prevState,
      facility: option,
      firstSearch: false,
      data: [],
      FacilityUserAccountsTable: null,
    }));
  };

  const loadRoles = (corporationOptions: SelectOption[]): void => {
    loadRoleOptionsPromise().then((roleOptions) => {
      setSearchState((current) => ({
        ...current,
        role: roleOptions.find((x) => x.value === current.role.value) ? current.role : roleOptions[0],
        roleOptions: roleOptions,
        availableCorporations: corporationOptions,
      }));
    });
  };

  const handleCorporationSelectChange = useCallback(
    (option: SelectOption) => {
      if (option.value !== '') {
        FacilityUserTools.getUserFacilityOptions(parseInt(option.value, 10))
          .then((facilityOptions: SelectOption[]) => {
            if (searchState.corporation?.value !== option.value) {
              setSearchState((current) => ({
                ...current,
                availableFacilities: [],
              }));
            }
            if (facilityOptions.length > 2) {
              setSearchState((current) => ({
                ...current,
                corporation: option,
                availableFacilities: facilityOptions,
                facility: facilityOptions.find((x) => x.value === current.facility?.value) ? current.facility : facilityOptions[0],
              }));
            } else {
              setSearchState((current) => ({
                ...current,
                corporation: option,
                availableFacilities: facilityOptions,
                facility: facilityOptions.find((x) => x.value === current.facility?.value) ? current.facility : facilityOptions[1],
              }));
            }
          })
          .catch((errorResponse: any) => {
            console.log(errorResponse);
          });
      } else {
        setSearchState((current) => ({
          ...current,
          corporation: option,
          availableFacilities: [],
          facility: facility,
        }));
      }
    },
    [searchState.corporation?.value]
  );

  React.useEffect(() => {
    DataListsProxy.getUserCorporationList()
      .then((corporationList) => {
        const corporationOptions: SelectOption[] = [{ label: 'All Corporations', value: '' }];

        corporationList
          .sort((a, b) => {
            if (a.corporationName.toLowerCase() < b.corporationName.toLowerCase()) return -1;
            if (a.corporationName.toLowerCase() > b.corporationName.toLowerCase()) return 1;
            return 0;
          })
          .forEach((element) => {
            if (element.corporationName && element.corporationID) {
              corporationOptions.push({
                label: element.corporationName,
                value: element.corporationID.toString(),
              });
            }
          });

        loadRoles(corporationOptions);

        if (corporationOptions.length <= 2) {
          handleCorporationSelectChange(corporationOptions[1]);
        }
      })
      .catch((errorResponse) => {
        console.log(errorResponse);
      });
  }, [handleCorporationSelectChange]);

  const goToFacilityUserRegistration = (): void => {
    history.push(AllRoutes.FACILITY_USER_REGISTRATION);
  };

  const goToFacilityUserEdit = (row: FacilityUserDataRow, e: React.MouseEvent<Element, MouseEvent>): void => {
    e.preventDefault();
    history.push(`${AllRoutes.FACILITY_USER_EDIT}/${row.accountID}`);
  };

  // ****clear data
  const clearSearchCriteria = (): void => {
    const roleOptionList: SelectOption[] = searchState.roleOptions;
    const corporationOptions: SelectOption[] = searchState.availableCorporations;

    setSearchState((prevState) => ({
      ...prevState,
      firstName: '',
      lastName: '',
      email: '',
      roleOptions: roleOptionList,
      role: roleOptionList[0],
      availableCorporations: [],
      corporation: corporationOptions[0],
      availableFacilities: [],
      facility: facility,
      firstSearch: false,
      data: [],
      FacilityUserAccountsTable: null,
    }));

    handleCorporationSelectChange(corporationOptions[0]);
    loadRoles(corporationOptions);
  };

  const disableClearButton = (): boolean => {
    try {
      if (
        searchState.corporation &&
        searchState.facility &&
        searchState.role &&
        searchState.firstName === '' &&
        searchState.lastName === '' &&
        searchState.email === '' &&
        (!searchState.corporation?.value || searchState.corporation.value === searchState.availableCorporations[0].value) &&
        (!searchState.facility?.value || (searchState.availableFacilities && searchState.facility.value === searchState.availableFacilities[0].value)) &&
        (!searchState.role?.value || searchState.role.value === Roles.NONE)
      ) {
        return true;
      }
    } catch (e) {
      console.log(e);
    }

    return false;
  };

  const search = (): void => {
    const searchCriteria: FacilityUserSearchCriteria = {
      fname: searchState.firstName,
      lname: searchState.lastName,
      email: searchState.email,
      corpid: searchState.corporation?.value ?? '',
      fid: searchState.facility?.value ?? '',
      rid: searchState.role?.value ?? '',
      acstyid: searchState.accessType?.value ?? '',
    };

    facilityUsersProxy.Search(
      searchCriteria,
      (response) => {
        const FacilityUserDataRows: FacilityUserDataRow[] = [];

        if (response.data) {
          response.data.forEach((facilityUser) => {
            let userHasMultipleFacilities = false;

            for (let i = 0; i < FacilityUserDataRows.length; i += 1) {
              if (FacilityUserDataRows[i].accountID === facilityUser.accountID) {
                userHasMultipleFacilities = true;
                FacilityUserDataRows[i].facility = 'Multiple';
              }
            }

            if (!userHasMultipleFacilities) {
              FacilityUserDataRows.push({
                accountID: facilityUser.accountID,
                name: facilityUser.accountName,
                email: facilityUser.accountEmail,
                corporation: facilityUser.corporationName,
                facility: facilityUser.facilityName,
                role: facilityUser.userRoleName,
                status: facilityUser.statusName,
                accessType: facilityUser.accessType,
              } as FacilityUserDataRow);
            }
          });
        }

        setSearchState((current) => ({
          ...current,
          data: FacilityUserDataRows,
          firstSearch: true,
          facility,
        }));
      },
      (errorResponse) => {
        if (errorResponse.data === null) {
          setSearchState((current) => ({
            ...current,
            data: [],
          }));
        }
      }
    );
  };

  const showFacilityDropDown = user?.role === 'admin' || user?.role === 'super-admin';

  const onChangePage = (page: number): void => {
    Utilities.setCellTitles();

    setSearchState((prevState) => ({
      ...prevState,
      currentPage: page,
    }));
  };

  const onChangeAccessType = (row: FacilityUserDataRow, e: React.MouseEvent<Element, MouseEvent>): void => {
    openAccessTypeSelectionWindow(row);
  };

  const openAccessTypeSelectionWindow = (row: FacilityUserDataRow): void => {
    setSearchState((current) => ({
      ...current,
      showAccessTypeSelectionWindow: true,
      accessTypeSelectionWindowAccountId: row.accountID ? row.accountID : -1,
      accessTypeSelectionWindowSelectedAccessType: { label: row.accessType, value: row.accessTypeId?.toString() },
    }));
  };

  const loadAccessTypeOptionsPromise = (accountId: number): Promise<SelectOption[]> =>
    new Promise<SelectOption[]>((resolve, reject) => {
      const facilityId = 0; // 0 = All Facilities/Optional Parameter Currently
      DataListsProxy.getAccessTypes(
        facilityId,
        accountId,
        (response) => {
          const loadedAccessTypes: SelectOption[] = [];

          response.data?.forEach((element) => {
            if (element.accessType) {
              loadedAccessTypes.push({ label: element.accessType, value: element.accessTypeID.toString() });
            }
          });

          if (loadedAccessTypes.length > 0) {
            setSearchState((current) => ({
              ...current,
              accessTypeOptions: loadedAccessTypes,
            }));
          }
          resolve(loadedAccessTypes);
        },
        (errorResponse) => {
          console.log(errorResponse);
          reject(errorResponse);
        }
      );
    });

  const handleAccessTypeSelectChange = (option: SelectOption): void => {
    setSearchState((current) => ({
      ...current,
      accessTypeSelectionWindowSelectedAccessType: option,
    }));
  };

  const handleSearchAccessTypeSelectChange = (option: SelectOption): void => {
    setSearchState((current) => ({
      ...current,
      accessType: option,
    }));
  };

  const closeAccessTypeSelectionWindow = (): void => {
    setSearchState((current) => ({
      ...current,
      showAccessTypeSelectionWindow: false,
    }));
  };

  const isValueSet = (value: any): boolean => !!value;

  const invalidAccessType = (): boolean => !isValueSet(searchState.accessTypeSelectionWindowSelectedAccessType);

  const saveAccessTypeSelection = (): void => {
    const accountId = searchState.accessTypeSelectionWindowAccountId;
    const accessTypeId = parseInt(searchState.accessTypeSelectionWindowSelectedAccessType ? searchState.accessTypeSelectionWindowSelectedAccessType.value : '-1', 10);
    UserProxy.postUserAccountAccessType(
      accountId,
      accessTypeId,
      () => {
        closeAccessTypeSelectionWindow();
        search();
      },
      (resp) => {
        console.log('Error', resp);
      }
    );
  };

  return (
    <UserContext.Consumer>
      {(userContext) => (
        <PageLayout layout={PageLayoutVariant.PADDED} testText="Facility User Search Page">
          <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="non-patient-search-content">
            <PrimaryHeader text="Facility User Search" fontColor={FontColors.PRIMARY} marginBottomPx={16} />
            <form onSubmit={search}>
              <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="non-patient-search-content__text-inputs">
                <LabledTextInput
                  label="First Name"
                  placeholder="Search By First Name..."
                  name="firstName"
                  value={searchState.firstName}
                  onChange={handleInputChange}
                  type="text"
                  variant={TextInputVariant.PRIMARY}
                  inputRef={inputRef}
                  autoFocus
                />
                <LabledTextInput
                  label="Last Name"
                  placeholder="Search By Last Name..."
                  name="lastName"
                  value={searchState.lastName}
                  onChange={handleInputChange}
                  type="text"
                  variant={TextInputVariant.PRIMARY}
                />
                <LabledTextInput
                  label="User Email"
                  placeholder="Search By User Email..."
                  name="email"
                  value={searchState.email}
                  onChange={handleInputChange}
                  type="email"
                  variant={TextInputVariant.PRIMARY}
                />
              </FlexContainer>
              <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.CENTER} justify={JustifyVariant.SPACE_BETWEEN} extraClasses="non-patient-search-content__dropdown-inputs">
                <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="non-patient-search-content__dropdown-inputs__labels">
                  {searchState.availableCorporations.length === 0 && (
                    <LabledSingleSelect
                      label="Corporation"
                      styles={SingleStandardDropdownStyles}
                      options={[]}
                      defaultValue={{ label: 'Loading...', value: '' }}
                      value={searchState.corporation || undefined}
                      onSelection={handleCorporationSelectChange}
                    />
                  )}
                  {searchState.availableCorporations.length > 2 && (
                    <LabledSingleSelect
                      label="Corporation"
                      styles={SingleStandardDropdownStyles}
                      options={searchState.availableCorporations}
                      defaultValue={{ label: 'All Corporations', value: '' }}
                      value={searchState.corporation || undefined}
                      onSelection={handleCorporationSelectChange}
                    />
                  )}
                  {showFacilityDropDown && searchState.corporation && searchState.corporation.value !== '' && searchState.availableFacilities.length > 2 && (
                    <LabledSingleSelect
                      label="Facility"
                      styles={SingleStandardDropdownStyles}
                      options={searchState.availableFacilities}
                      defaultValue={searchState.availableFacilities[0]}
                      value={searchState.facility || undefined}
                      onSelection={handleFacilitySelectChange}
                    />
                  )}
                  {searchState.availableCorporations.length > 0 && searchState.roleOptions && (
                    <LabledSingleSelect
                      label="User Role"
                      styles={SingleStandardDropdownStyles}
                      options={searchState.roleOptions}
                      defaultValue={searchState.roleOptions[0]}
                      value={searchState.role || undefined}
                      onSelection={handleRoleSelectChange}
                    />
                  )}
                  {isCoreMeasuresEnabled && (
                    <LabledAsyncSingleSelect
                      label="Access Type"
                      styles={SingleStandardDropdownStyles}
                      loadOptions={() => loadAccessTypeOptionsPromise(parseInt(user ? user.accountId : '-1', 10))}
                      defaultValue={{ label: 'Select an Access Type...', value: '' }}
                      value={searchState.accessType || undefined}
                      onSelection={handleSearchAccessTypeSelectChange}
                    />
                  )}
                </FlexContainer>
              </FlexContainer>
              <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.END} align={AlignVariant.END} extraClasses="button-container__search">
                <Button variant={ButtonVariant.SECONDARY} onClick={clearSearchCriteria} disabled={disableClearButton()}>
                  <InlineText text="Clear" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
                </Button>
                <Button variant={ButtonVariant.PRIMARY} onClick={search} submit>
                  <InlineText text="Search" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
                </Button>
              </FlexContainer>
            </form>
            {(searchState.data.length > 0 || searchState.firstSearch) && (
              <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="card-primary">
                <FacilityUserAccountsTable
                  data={searchState.data}
                  onRowClicked={goToFacilityUserEdit}
                  onChangePage={onChangePage}
                  defaultPage={searchState.currentPage}
                  onChangeAccessType={onChangeAccessType}
                  isCoreMeasuresEnabled={isCoreMeasuresEnabled}
                />
              </FlexContainer>
            )}
            <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.START} align={AlignVariant.START} extraClasses="button-container__add-account">
              <Button variant={ButtonVariant.PRIMARY} onClick={goToFacilityUserRegistration} disabled={!searchState.firstSearch}>
                <InlineText text="Add Account" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
              </Button>
            </FlexContainer>
            {searchState.showAccessTypeSelectionWindow && (
              <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.CENTER} align={AlignVariant.CENTER} extraClasses="popup-window-wrapper">
                <PopupWindow>
                  <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.START} align={AlignVariant.START} extraClasses="popup-window-content">
                    <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.SPACE_BETWEEN} align={AlignVariant.CENTER} extraClasses="popup-window-header">
                      <TertiaryHeader text="Facility User Registration" fontColor={FontColors.PRIMARY} />
                      <Button
                        variant={ButtonVariant.INVISIBLE}
                        onClick={(e) => {
                          e?.preventDefault();
                          e?.stopPropagation();
                          closeAccessTypeSelectionWindow();
                        }}
                      >
                        <X />
                      </Button>
                    </FlexContainer>
                    <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.START} align={AlignVariant.START} extraClasses="dropdown-input">
                      <LabledAsyncSingleSelect
                        label="Access Type"
                        styles={SingleStandardDropdownStyles}
                        loadOptions={() => loadAccessTypeOptionsPromise(searchState.accessTypeSelectionWindowAccountId)}
                        defaultValue={{ label: 'Select an Access Type...', value: '' }}
                        value={searchState.accessTypeSelectionWindowSelectedAccessType || undefined}
                        onSelection={handleAccessTypeSelectChange}
                      />
                    </FlexContainer>
                  </FlexContainer>
                  <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.END} align={AlignVariant.END} extraClasses="popup-window-content">
                    <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.END} align={AlignVariant.END} extraClasses="popup-window-buttons">
                      <Button variant={ButtonVariant.SECONDARY} onClick={closeAccessTypeSelectionWindow}>
                        <InlineText text="Cancel" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
                      </Button>
                      <Button variant={ButtonVariant.PRIMARY} disabled={invalidAccessType()} onClick={saveAccessTypeSelection}>
                        <InlineText text="Save" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
                      </Button>
                    </FlexContainer>
                  </FlexContainer>
                </PopupWindow>
              </FlexContainer>
            )}
          </FlexContainer>
        </PageLayout>
      )}
    </UserContext.Consumer>
  );
};

export default FacilityUserAccountsPage;
