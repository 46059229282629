import * as React from 'react';

import './styles.scss';

import Button from 'global_elements/Button';
import FlexContainer from 'global_elements/Layouts/FlexContainer';

import { CollapsibleSectionProps } from 'types/collapsibleSectionProps';
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { ButtonVariant } from 'global_elements/Button/variants';

import { ReactComponent as UpArrow } from 'icons/mho-up-arrow-icon.svg';
import { ReactComponent as DownArrow } from 'icons/mho-down-arrow-icon.svg';

const CollapsibleSection = (props: CollapsibleSectionProps): JSX.Element => {
  const [isExpanded, setIsExpanded] = React.useState<boolean>(props.expandedOnDefault ?? false);
  const arrowCss = props.arrowAtLeft ? 'arrow--left' : '';
  const printClass = props.noPrint ? 'no-print' : '';

  const toggleCollapsed = (): void => {
    setIsExpanded(!isExpanded);
  };

  const getArrow = (): JSX.Element => (isExpanded ? <UpArrow /> : <DownArrow />);

  return (
    <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses={`collapsible-section ${printClass}`}>
      <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="collapsible-section__banner">
        <Button variant={ButtonVariant.INVISIBLE} onClick={toggleCollapsed}>
          <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.CENTER} justify={JustifyVariant.CENTER} extraClasses={`collapsible-section__banner__container ${arrowCss}`}>
            {props.arrowAtLeft && getArrow()}
            {props.banner}
            {!props.arrowAtLeft && getArrow()}
          </FlexContainer>
        </Button>
      </FlexContainer>
      <FlexContainer
        display={isExpanded ? DisplayVariant.FLEX_COL : DisplayVariant.HIDE}
        align={AlignVariant.START}
        justify={JustifyVariant.START}
        extraClasses={`collapsible-section__content ${arrowCss}`}
      >
        {props.children}
      </FlexContainer>
      {props.bottomDivider && <hr />}
    </FlexContainer>
  );
};

export default CollapsibleSection;
