import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { ReactComponent as UpArrow } from 'icons/mho-up-arrow-icon.svg';
import moment from 'moment';

import { PatientAssessmentDefinition } from 'domain/Forms/MHO/PatientAssessmentDefinition';
import { AssessmentSubscales } from 'domain/Forms/MHO/AssessmentSubscales';
import MHODateTime from 'domain/dateTime/MHODateTime';

import Button from 'global_elements/Button';
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { ButtonVariant } from 'global_elements/Button/variants';
import FlexContainer from 'global_elements/Layouts/FlexContainer';
import PageLayout from 'global_elements/Layouts/PageLayout';
import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import TertiaryHeader from 'global_elements/Text/TertiaryHeader';
import InlineText from 'global_elements/Text/InlineText';
import PatientInfoCard from 'global_elements/Layouts/Cards/PatientInfoCard';
import { PatientScoreCard } from 'global_elements/Layouts/Cards/PatientScoreCard/patientScoreCard';
import { SingleStandardDropdownStyles } from 'global_elements/Inputs/Dropdown/SingleSelect/styles';
import LabledAsyncSingleSelect from 'global_elements/Inputs/Dropdown/SingleSelect/LabledSingleSelect/asyncLabledSingleSelct';
import PrintSignature from 'global_elements/Text/PrintSignature';
import PrintFooter from 'global_elements/Text/PrintFooter';
import Paragraph from 'global_elements/Text/Paragraph';
import { PatientData, getNewPatientData } from 'interfaces/patients/patientData';

import Utilities from 'api/lib/Utilities';
import AssessmentProxy from 'api/assessments/assessmentProxy';
import PatientUsersProxy from 'api/patientUsers/patientUsersProxy';

import { UserContext } from 'context/user';
import { AssessmentSetupMode, getAssessmentSetupMode } from 'constants/assessment_types';
import { Roles } from 'constants/roles';
import { PatientRoutes } from 'constants/routes';
import { SelectOption } from 'types/inputProps';

import { awSortOrders } from 'pages/shared/AssessmentResult/appliedWhenSortOrder';
import AssessmentDefinitionProxy from 'api/assessments/assessmentDefinitionProxy';
import AssessmentJSONDefinition from 'interfaces/assessments/AssessmentJSONDefinition';
import AssessmentResultsChart from './assessmentResultsChart';
import { AssessmentCallbackData } from './assessmentCallbackData';
import { AssessmentResultsTableComponent } from './assessmentResultsTable';
import './styles.scss';

/**
 * Get API-sorted assessment list and filter by
 * optional patientID, maxDate and sort by date.
 *
 * @param rawAssessmentList list of assessments to filter.
 * @param patientID optional. PatientID to match the assessment list.
 * @param maxDate optional. Maximum date to show.
 * @param sequence optional. sequence number for component/subscale.
 * @returns sorted list of assessments that matches the instrument type ID.
 */
function getInstrumentAssessments(
  rawAssessmentList: PatientAssessmentDefinition[],
  patientID?: number,
  maxDate: string | null = null,
  maxAppliedWhenId?: string | null,
  sequence?: number,
): PatientAssessmentDefinition[] {
  if (Utilities.isEmpty(rawAssessmentList)) {
    return rawAssessmentList;
  }

  let filteredList = rawAssessmentList.map((v) => {
    const awidSortOrder = awSortOrders.find((c) => c.awid === v.appliedWhenID);
    return { ...v, awidSort: awidSortOrder ? awidSortOrder.sortOrder : 8 };
  });

  const maxAppliedWhenSort = awSortOrders.find((c) => c.awid.toString() === maxAppliedWhenId);

  if (patientID || maxDate || sequence) {
    filteredList = filteredList.filter(
      (assessment) => (!sequence || assessment.subscaleSequence === sequence)
        && (!patientID || assessment.patientID === patientID)
        && (!maxDate || moment(assessment.dateProcessed).isSameOrBefore(maxDate))
        && (!maxAppliedWhenSort || assessment.awidSort <= maxAppliedWhenSort.sortOrder),
    );
  }

  return filteredList;
}

/**
 * Get custom sorted list of assessments for non-patients
 *
 * @param rawAssessmentList list of assessments to filter.
 * @param patientID optional. PatientID to match the assessment list.
 * @param maxDate optional. Maximum date to show.
 * @param sequence optional. sequence number for component/subscale.
 * @returns sorted list of assessments that matches the instrument type ID.
 */
function filterInstrumentAssessment(
  rawAssessmentList: PatientAssessmentDefinition[],
  patientID?: number,
  maxDate: string | null = null,
  maxAppliedWhenId?: string | null,
  sequence?: number,
): PatientAssessmentDefinition[] {
  const filteredList = getInstrumentAssessments(rawAssessmentList, patientID, maxDate, maxAppliedWhenId, sequence);
  return filteredList.sort((a, b) => {
    if (a.awidSort === b.awidSort) {
      if (a.dateProcessed === b.dateProcessed) {
        const aTime = moment(a.timeProcessed, 'HH:mm');
        const bTime = moment(b.timeProcessed, 'HH:mm');
        return aTime.isAfter(bTime) ? 1 : -1;
      }
      return a.dateProcessed > b.dateProcessed ? 1 : -1;
    }
    return a.awidSort > b.awidSort ? 1 : -1;
  });
}

/**
 * Patient's assessment results page.
 */
type uriParams = {
  careID: string;
  instrumentTypeID: string;
  patientID: string;
};

function AssessmentResultsPage(): JSX.Element {
  const assessmentListRef = useRef<PatientAssessmentDefinition[]>([]);
  const jumpToRefs = useRef<(HTMLDivElement | null)[]>([]);
  const assessmentResultsChartRefs = useRef<(AssessmentResultsChart | null)[]>([]);

  const { user } = React.useContext(UserContext);
  const params = useParams<uriParams>();
  const [careID] = useState<number>(parseInt(params.careID, 10));
  const [instrumentTypeID] = useState<number>(parseInt(params.instrumentTypeID, 10));
  const [patientID] = useState<number>(parseInt(params.patientID, 10));

  const paramSearch = useLocation().search;
  const maxDate = new URLSearchParams(paramSearch).get('date');
  const maxAppliedWhenId = new URLSearchParams(paramSearch).get('awid');
  const daisyChain = new URLSearchParams(paramSearch).get('daisyChain');
  // latestResponse: undefined = uninitialized. null = assessment not found after checking.
  const [latestResponse, setLatestResponse] = useState<PatientAssessmentDefinition | undefined | null>(undefined);
  const [selectedAssessment, setSelectedAssessment] = useState<AssessmentCallbackData | null>(null);
  const [patientInfo, setPatientInfo] = useState<PatientData>(getNewPatientData());
  const [isPatient, setIsPatient] = useState<boolean>(true);
  const [bannerHtml, setBannerHtml] = useState<any>(null);

  const [assessmentList, setAssessmentList] = useState<PatientAssessmentDefinition[]>([]);
  const [unsortedAssessmentList, setUnsortedAssessmentList] = useState<PatientAssessmentDefinition[]>([]);
  const [assessmentSetup, setAssessmentSetup] = useState<AssessmentSetupMode>(AssessmentSetupMode.OVERALL);
  const [subscaleList, setSubscaleList] = useState<AssessmentSubscales[]>([]);
  const [isOverallView, setIsOverallView] = useState<boolean>(true);
  const [printPageSubtitle, setPrintPageSubtitle] = useState<string>('');
  const [selectedAssessmentOption, setSelectedAssessmentOption] = useState<SelectOption>();
  const [assessmentNumberForJson, setAssessmentNumberForJson] = useState<any>();
  const [assessmentJsonQuestions, setAssessmentJsonQuestions] = useState<any>();

  const [scoresLoadedCount, setScoreLoadedCount] = useState<number>(0);
  const [chartsLoadedCount, setChartsLoadedCount] = useState<number>(0);
  const [tablesLoadedCount, setTablesLoadedCount] = useState<number>(0);
  const [printEnabled, setPrintEnabled] = useState<boolean>(false);
  const history = useHistory();
  const [dateProcessedOption, setDateProcessedOption] = useState<any>(maxDate);

  const loadType = {
    score: 'SCORE',
    chart: 'CHART',
    table: 'TABLE',
  };

  const onItemLoaded = (itemType: string): void => {
    switch (itemType) {
      case loadType.score:
        setScoreLoadedCount(scoresLoadedCount + 1);
        break;
      case loadType.chart:
        setChartsLoadedCount(chartsLoadedCount + 1);
        break;
      case loadType.table:
        setTablesLoadedCount(tablesLoadedCount + 1);
        break;
      default:
        break;
    }
  };

  function getAssessmentLabel(assessment: PatientAssessmentDefinition, includeTime: boolean): string[] {
    const descriptors = [];
    descriptors.push(isPatient ? '' : assessment.appliedWhenDesc);
    const dateProcessed = new MHODateTime(String(assessment.dateProcessed)).getFormattedCalendarDate();
    descriptors.push(dateProcessed);
    if (isPatient) {
      return descriptors;
    }

    if (includeTime && assessment.timeProcessed) {
      descriptors.push(assessment.timeProcessed);
    }
    return descriptors;
  }

  function stripBr(html: string): string {
    return html.replace('<br>', ' ').replace('<br/>', ' ').replace('<br />', ' ');
  }

  /**
   * Select the assessment to update the "left" selected column in the table.
   *
   * @param assessment assessment data to chose for the "Selected Column"
   */
  const selectAssessment = (assessment: PatientAssessmentDefinition): void => {
    const label = getAssessmentLabel(assessment, false);
    const assementCallbackData: AssessmentCallbackData = {
      label,
      instrumentTypeID,
      name: stripBr(assessment.assessmentDescription),
      patientID: assessment.patientID,
      assessmentNumber: assessment.assessmentNumber,
      dateProcessed: assessment.dateProcessed.toString(),
      appliedWhenID: assessment.appliedWhenID,
      appliedWhenSequenceNumber: assessment.sequenceNumber,
    };

    setSelectedAssessment(assementCallbackData);
  };

  /**
   * Retrieve the assessment taken data.
   *
   * @returns {Promise} retrieves the assessment scores, which has the most of the necessary assessment data for the general page.
   */
  const getAssessmentsPromise = (): Promise<PatientAssessmentDefinition[]> => {
    if (isPatient) {
      return AssessmentProxy.getUserTakenAssessments(careID, instrumentTypeID);
    }

    return AssessmentProxy.getPatientTakenAssessments(patientID, instrumentTypeID);
  };

  useEffect(() => {
    getAssessmentsPromise()
      .then((takenAssessmentsList: PatientAssessmentDefinition[]) => {
        if (takenAssessmentsList.length) {
          const { instrumentSetup } = takenAssessmentsList[0];
          setAssessmentSetup(getAssessmentSetupMode(instrumentSetup));
        }

        const unsortedList: PatientAssessmentDefinition[] = getInstrumentAssessments(takenAssessmentsList, patientID, maxDate, maxAppliedWhenId);
        const filteredList: PatientAssessmentDefinition[] = filterInstrumentAssessment(takenAssessmentsList, patientID, maxDate, maxAppliedWhenId);
        setUnsortedAssessmentList(unsortedList);
        setAssessmentList(filteredList);
      })
      .catch((errorResponse) => {
        console.log(errorResponse);
      });
  }, []);

  useEffect(() => {
    if (!assessmentNumberForJson) {
      return;
    }
    AssessmentDefinitionProxy.getAssessmentJSONDefinition(assessmentNumberForJson).then((value: AssessmentJSONDefinition) => {
      try {
        if (value.assessmentEditorJSON) {
          const json = JSON.parse(value.assessmentEditorJSON);
          const allElements: any = [];
          json.pages.forEach((p: any) => {
            p.elements.forEach((e: any) => allElements.push(e));
          });
          setAssessmentJsonQuestions(allElements);
        }
      } catch (e) {
        console.log('Error setting JSON questions for look-up.');
        console.log(e);
      }
    });
  }, [assessmentNumberForJson]);

  useEffect(() => {
    const subscalesCount = subscaleList.length;
    const tablesComparator = isOverallView ? 1 : subscalesCount;
    const chartsComparator = isOverallView ? subscalesCount : subscalesCount + 1;
    const tablesLoaded = tablesLoadedCount >= tablesComparator;
    const chartsLoaded = chartsLoadedCount >= chartsComparator;
    const readyToPrintSubscales = !isOverallView ? subscalesCount > 0 : true;
    const readyToPrint = readyToPrintSubscales && tablesLoaded && chartsLoaded;
    const delayForRendering = readyToPrint && !printEnabled ? 750 : 0;
    setTimeout(() => setPrintEnabled(readyToPrint), delayForRendering);
  }, [tablesLoadedCount, chartsLoadedCount, scoresLoadedCount]);

  /**
   * Retrieve the initial assessment results and the latest response.
   */
  useMemo(() => {
    setIsPatient(user?.role === Roles.PATIENT);
  }, [user]);

  /**
   * Load Assessment Scores.
   */
  useEffect(() => {
    if (assessmentSetup !== AssessmentSetupMode.OVERALL) {
      // set and show the subgroups initially for components.
      setIsOverallView(assessmentSetup !== AssessmentSetupMode.COMPONENTS);

      AssessmentProxy.getAssessmentSubscales(instrumentTypeID)
        .then((subscales: AssessmentSubscales[]) => {
          setSubscaleList(subscales);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }, [assessmentSetup, careID, instrumentTypeID]);

  /**
   * Non-Patient View: Retrieve the selected patient information.
   */
  useEffect(() => {
    if (!isPatient) {
      PatientUsersProxy.getPatient(
        patientID,
        (response: any) => {
          if (response.data) {
            const patientData: PatientData = response.data[0];
            setPatientInfo(patientData);
          }
        },
        (errorResponse: any) => {
          console.log(errorResponse);
        },
      );
    }
  }, [patientID, isPatient]);

  /**
   * Create and populate the assessments dropdown with the assessment options.
   *
   * @returns {SelectOption[]} a list of options for the Assessment Dropdown.
   */
  const getAssessmentOptions = (): SelectOption[] => {
    const newAssessmentOptions: SelectOption[] = [];
    const assessmentSource = isPatient ? unsortedAssessmentList : assessmentList;
    for (let i = 0; i < assessmentSource.length; i += 1) {
      const label = getAssessmentLabel(assessmentSource[i], true);
      const newOption: SelectOption = { label: label.join(' '), value: String(i) };
      newAssessmentOptions.push(newOption);
    }

    return newAssessmentOptions;
  };

  /**
   * Create and populate the assessments dropdown with the assessment options.
   *
   * @returns {Promise} a list of options for the Assessment Dropdown.
   */
  const loadAssessmentsPromise = (): Promise<SelectOption[]> => new Promise<SelectOption[]>((resolve) => {
    resolve(getAssessmentOptions());
  });

  /**
   * Create and populate the jump to subscales dropdown with the subscale options.
   *
   * @returns {Promise} a list of options for the subscales Dropdown.
   */
  const loadSubscalesPromise = (): Promise<SelectOption[]> => new Promise<SelectOption[]>((resolve, reject) => {
    AssessmentProxy.getAssessmentSubscales(instrumentTypeID)
      .then((subscales: AssessmentSubscales[]) => {
        const newAssessmentOptions: SelectOption[] = subscales.map((s) => ({ label: s.subscale, value: `${s.subscaleSequence}` }));
        resolve(newAssessmentOptions);
      })
      .catch((error: any) => {
        console.log(error);
        reject(error);
      });
  });

  /**
   * A callback handler for when the Assessment select dropdown changes.
   *
   * @param selectedOption Drop down selection.
   */
  const handleSelectAssessmentDropDown = (selectedOption: SelectOption): void => {
    const assessments: PatientAssessmentDefinition[] = assessmentListRef.current;
    const selectedValue: number = parseInt(selectedOption.value, 10);
    console.assert(assessments.length >= selectedValue, 'Illegal Range');

    if (!assessments.length || Number.isNaN(selectedValue)) {
      return;
    }

    setSelectedAssessmentOption({ ...selectedOption });
    selectAssessment(assessments[selectedValue]);
    // Updating score
    const MHODateTimeDateProcesssed = new MHODateTime(new Date(selectedOption.label)).getDateTimeForPost();
    setDateProcessedOption(MHODateTimeDateProcesssed);
    assessmentResultsChartRefs.current.forEach((chart) => chart?.loadScores());
  };

  /**
   * Non-Patient View: Add a banner to show the select Patient's info.
   */
  useEffect(() => {
    if (patientInfo.careID !== -1 && !bannerHtml) {
      setBannerHtml(
        <PatientInfoCard
          patientID={String(patientID)}
          patientFirstName={patientInfo.patientFirstName}
          patientLastName={patientInfo.patientLastName}
          patientDOB={patientInfo.dateOfBirth}
          patientAccountNumber={patientInfo.patientNumber}
          patientMedicalRecordNumber={patientInfo.medicalRecordNumber}
          patientProgram={patientInfo.programName}
          patientAdmissionDate={patientInfo.dateAdmitted}
          patientRegistrationPIN={patientInfo.registrationPIN?.toString() ?? ''}
          showRegisterButton={false}
        />,
      );
    }
  }, [patientInfo]);

  useEffect(() => {
    if (Utilities.isEmpty(assessmentList)) {
      setLatestResponse(null);
    } else {
      const assessmentSource = isPatient ? unsortedAssessmentList : assessmentList;
      assessmentListRef.current = assessmentSource;
      const defaultAssessment: PatientAssessmentDefinition = isPatient ? assessmentSource[0] : assessmentSource[assessmentSource.length - 1];
      setLatestResponse(defaultAssessment);
      setAssessmentNumberForJson(defaultAssessment.assessmentNumber);

      // Select the first, "Admission" assessment. For patients, use the currently chosen assessment and,
      // if a date is present in the URL, select print subtitle.
      const assessment = isPatient || assessmentSetup === AssessmentSetupMode.CORE_MEASURES ? defaultAssessment : assessmentList[0];
      if (maxDate) {
        // Ensure comparison date has time marked since it is empty when coming directly from a submission.
        const compareDate = maxDate.indexOf('T') < 0 ? `${maxDate}T00:00:00` : maxDate;
        const datedAssessment = assessmentList
          .slice()
          .reverse()
          .find((a) => a.dateProcessed.toString() === compareDate);
        if (!selectedAssessment && datedAssessment && user?.role !== Roles.PATIENT) {
          setPrintPageSubtitle(getAssessmentLabel(datedAssessment, true).join(' '));
        }
      }
      selectAssessment(assessment);
    }
  }, [assessmentList]);

  /**
   * A callback to handle displaying the selected assessment into the table.
   * NOTE: This function is disabled for the moment on MHO's request.
   *
   * @param assessment assessment identifier -- patient ID, assessment number, instrument type ID, and applied when ID.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const handleSelectAssessmentCallback = (assessment: AssessmentCallbackData): void => {
    // eslint-disable-line no-unused-vars
    // setSelectedAssessment(assessment);
  };

  /**
   * A callback handler for when the subscales select dropdown changes to jump to the chart/table.
   *
   * @param selectedOption Drop down selection.
   */
  const handleSelectSubscalesDropDown = (selectedOption: SelectOption): void => {
    const selectedSequence: number = parseInt(selectedOption.value, 10);
    const jumpSequence: HTMLDivElement | null = jumpToRefs.current[selectedSequence];

    if (!selectedSequence || Number.isNaN(selectedSequence) || !jumpSequence) {
      return;
    }

    window.scrollTo({
      top: jumpSequence.offsetTop,
      behavior: 'smooth',
    });
  };

  /**
   * Handle "Overall" vs "Subscale" tab selection.
   *
   * @param index
   * @param lastIndex
   * @returns
   */
  const onTabSelect = (index: number, lastIndex: number): boolean => {
    if (index === lastIndex) {
      return false;
    }

    setScoreLoadedCount(0);
    setTablesLoadedCount(0);
    setChartsLoadedCount(0);

    switch (index) {
      case 0: // Overall
        setIsOverallView(true);
        break;
      case 1: // Subscale
        setIsOverallView(false);
        break;
      default:
        break;
    }

    return true;
  };

  const getDaisyChainCard = (): JSX.Element => (
    <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="card-container--standalone daisy-chain-card no-print">
      <Paragraph text="You have more To-Do items remaining" fontColor={FontColors.PRIMARY} fontSize={FontSizes.LARGE} bold />
      <Button variant={ButtonVariant.PRIMARY} onClick={() => history.push(`${PatientRoutes.ASSESSMENTS}`)}>
        <InlineText text="Continue with the next assessment" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
      </Button>
    </FlexContainer>
  );

  const getAssessmentScore = (useTitle = false, showScores = true, showOverallView = true, subscaleSequence?: number): JSX.Element => (
    <FlexContainer
      display={DisplayVariant.FLEX_COL}
      align={AlignVariant.START}
      justify={JustifyVariant.START}
      ref={(elem) => {
        jumpToRefs.current[subscaleSequence ?? 0] = elem;
      }}
      extraClasses={useTitle ? 'card-primary-ref-wrapper score-container hidden' : 'card-container--standalone score-container hidden no-print'}
    >
      <PatientScoreCard
        isPatient={isPatient}
        patientID={patientID}
        careID={careID}
        instrumentTypeID={instrumentTypeID}
        isOverallView={showOverallView}
        sequence={subscaleSequence}
        dateProcessed={dateProcessedOption || ''}
        maxAppliedWhenId={maxAppliedWhenId ? Number.parseInt(maxAppliedWhenId, 10) : undefined}
        useTitle={useTitle}
        showScores={showScores}
        onLoaded={() => onItemLoaded(loadType.score)}
      />
    </FlexContainer>
  );

  const getAssessmentChart = (showOverallView = true, subscaleSequence?: number, isPrint = false): JSX.Element => (
    <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="card-primary chart-container hidden">
      <AssessmentResultsChart
        ref={(elem) => {
          assessmentResultsChartRefs.current[subscaleSequence ?? 0] = elem;
        }}
        careID={careID}
        instrumentTypeID={instrumentTypeID}
        isPatientView={isPatient}
        patientID={patientID}
        latestResponse={latestResponse}
        selectedAssessment={selectedAssessment}
        selectAssessmentCallback={handleSelectAssessmentCallback}
        isOverallView={showOverallView}
        sequence={subscaleSequence}
        maxAppliedWhenId={maxAppliedWhenId ? Number.parseInt(maxAppliedWhenId, 10) : undefined}
        isPrint={isPrint}
        onLoaded={() => onItemLoaded(loadType.chart)}
      />
    </FlexContainer>
  );

  const getAssessmentTable = (modeView: AssessmentSetupMode = AssessmentSetupMode.OVERALL, subscaleSequence?: number): JSX.Element => {
    const printClass = modeView !== AssessmentSetupMode.OVERALL && modeView !== AssessmentSetupMode.CORE_MEASURES ? 'no-print' : '';
    let tableKey = -2; // uninitialized
    if (latestResponse === null) {
      tableKey = -1; // Didn't find any assessments.
    } else if (latestResponse) {
      tableKey = latestResponse.subscaleSequence;
    }

    return (
      <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses={`table-widget card-primary extreme-response-handler ${printClass}`}>
        <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.CENTER} justify={JustifyVariant.SPACE_BETWEEN} extraClasses="card-primary__header">
          <TertiaryHeader text="Assessment Responses" fontColor={FontColors.PRIMARY} />
        </FlexContainer>
        <AssessmentResultsTableComponent
          key={tableKey}
          latestResponse={latestResponse}
          selectedAssessment={selectedAssessment}
          isPatientView={isPatient}
          suppressComparisonColumn={assessmentList.length <= 1 || assessmentSetup === AssessmentSetupMode.CORE_MEASURES}
          setupMode={modeView}
          subscaleSequence={subscaleSequence}
          onLoaded={() => onItemLoaded(loadType.table)}
          assessmentJsonQuestions={assessmentJsonQuestions}
        />
      </FlexContainer>
    );
  };

  const getSubscaleCard = (subscaleSequence: number): JSX.Element => (
    <div key={subscaleSequence} className="card-primary-ref-wrapper">
      <div className="break-inside-avoid">
        {getAssessmentScore(true, true, isOverallView, subscaleSequence)}
        {getAssessmentChart(isOverallView, subscaleSequence)}
      </div>
      {getAssessmentTable(assessmentSetup, subscaleSequence)}
    </div>
  );

  const getPrintSubscaleCharts = (): JSX.Element[] => subscaleList.map((subscale: any) => (
    <div key={subscale.subscaleSequence} className="screen-invisible break-inside-avoid">
      {getAssessmentScore(true, true, false, subscale.subscaleSequence)}
      {getAssessmentChart(false, subscale.subscaleSequence)}
    </div>
  ));

  const showOverallPanels = (): JSX.Element => (
    <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="card-primary-ref-wrapper overall-assessment-score">
      {getAssessmentScore(true, true)}
      {getAssessmentChart()}
      {getPrintSubscaleCharts()}
      {getAssessmentTable()}
    </FlexContainer>
  );

  const showCoreMeasurePanels = (): JSX.Element => (
    <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="card-primary-ref-wrapper overall-assessment-score">
      {getAssessmentScore(true, true)}
      {getAssessmentChart()}
      {getPrintSubscaleCharts()}
      {getAssessmentTable(assessmentSetup)}
    </FlexContainer>
  );

  const showSubscalePanels = (): JSX.Element => {
    if (Utilities.isEmpty(subscaleList)) {
      return <></>;
    }

    return (
      <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="card-primary-ref-wrapper">
        {!isOverallView && (
          <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.SPACE_BETWEEN} extraClasses="control-bar-top">
            <LabledAsyncSingleSelect
              key={`${assessmentList.length}`}
              propsKey={`${assessmentList.length}`}
              styles={SingleStandardDropdownStyles}
              label="Jump to..."
              loadOptions={loadSubscalesPromise}
              defaultValue={{ label: 'Select a Subscale', value: '' }}
              value={{ label: 'Select a Subscale', value: '' }}
              onSelection={handleSelectSubscalesDropDown}
            />
          </FlexContainer>
        )}
        <div className="screen-invisible">
          {getAssessmentScore(true)}
          {getAssessmentChart()}
        </div>
        {subscaleList.map((subscale: any) => getSubscaleCard(subscale.subscaleSequence))}
        <div className="screen-invisible">{getAssessmentTable()}</div>
      </FlexContainer>
    );
  };

  const getTabPanels = (): JSX.Element => {
    switch (assessmentSetup) {
      case AssessmentSetupMode.SUBSCALES:
        return (
          <Tabs onSelect={onTabSelect}>
            <TabList>
              <Tab>
                <InlineText text="Overall" fontColor={FontColors.DARK} fontSize={FontSizes.LARGE} />
              </Tab>
              <Tab>
                <InlineText text="Subscales" fontColor={FontColors.DARK} fontSize={FontSizes.LARGE} />
              </Tab>
            </TabList>
            <TabPanel>{showOverallPanels()}</TabPanel>
            <TabPanel>{showSubscalePanels()}</TabPanel>
          </Tabs>
        );
      case AssessmentSetupMode.COMPONENTS:
        return <>{showSubscalePanels()}</>;
      case AssessmentSetupMode.CORE_MEASURES:
        return <>{showCoreMeasurePanels()}</>;
      case AssessmentSetupMode.OVERALL:
      default:
        return <>{showOverallPanels()}</>;
    }
  };

  const getPatientPrintFooterInfo = (): string => {
    const admitted = patientInfo.dateAdmitted ? new MHODateTime(patientInfo.dateAdmitted).getFormattedCalendarDate() : '';
    return `${patientInfo.patientLastName}, ${patientInfo.patientFirstName}, Account #${patientInfo.patientNumber}, ${patientInfo.programName}, MRN ${patientInfo.medicalRecordNumber}, Admitted ${admitted}`;
  };

  return (
    <PageLayout
      layout={PageLayoutVariant.PADDED}
      title={selectedAssessment ? selectedAssessment.name : ''}
      subTitle={printPageSubtitle}
      testText="Assessment Results Page"
      bannerCard={bannerHtml}
      extraClasses="assessment-results"
    >
      {daisyChain && getDaisyChainCard()}
      {/* Hide assessment comparison dropdown when it's a core measure form */}
      {assessmentSetup !== AssessmentSetupMode.CORE_MEASURES ? (
        <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="super-admin-dashboard-content">
          <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.SPACE_BETWEEN} extraClasses="control-bar-top">
            <LabledAsyncSingleSelect
              key={`${assessmentList.length}`}
              propsKey={`${assessmentList.length}`}
              styles={SingleStandardDropdownStyles}
              label={isPatient ? 'Select an Assessment' : 'Comparison Assessment'}
              loadOptions={loadAssessmentsPromise}
              defaultValue={getAssessmentOptions().length ? getAssessmentOptions()[0] : null}
              value={selectedAssessmentOption || undefined}
              onSelection={handleSelectAssessmentDropDown}
            />
            <Button
              variant={ButtonVariant.PRIMARY}
              extraClasses="btn--print"
              disabled={!printEnabled}
              onClick={() => {
                setTimeout(() => window.print(), 500);
              }}
            >
              <InlineText text="Print" fontColor={FontColors.WHITE} fontSize={FontSizes.REGULAR} />
            </Button>
          </FlexContainer>
          {getTabPanels()}
        </FlexContainer>
      ) : (
        <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="super-admin-dashboard-content">
          <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.END} extraClasses="control-bar-top">
            <Button
              variant={ButtonVariant.PRIMARY}
              extraClasses="btn--print"
              disabled={!printEnabled}
              onClick={() => {
                setTimeout(() => window.print(), 500);
              }}
            >
              <InlineText text="Print" fontColor={FontColors.WHITE} fontSize={FontSizes.REGULAR} />
            </Button>
          </FlexContainer>
          {getTabPanels()}
        </FlexContainer>
      )}

      <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="status-bar-bottom">
        <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.END} justify={JustifyVariant.START} extraClasses="">
          <Button
            variant={ButtonVariant.INVISIBLE}
            extraClasses="scroll-to-top"
            tooltipText="Scroll to the Top"
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
            }}
          >
            <UpArrow />
          </Button>
        </FlexContainer>
      </FlexContainer>
      {!isPatient && (
        <>
          <PrintSignature />
          <PrintFooter patientInfo={getPatientPrintFooterInfo()} />
        </>
      )}
    </PageLayout>
  );
}

export default AssessmentResultsPage;
