import DataTable, { TableColumn } from 'react-data-table-component';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FlexContainer from 'global_elements/Layouts/FlexContainer';
import InlineText from 'global_elements/Text/InlineText';
import { ReactComponent as SortIcon } from 'icons/mho-sort-icon-white.svg';

import { GroupTherapySessionWorkListProps } from 'types/tableProps';
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';

import { GroupTherapySessionWorkListRow } from 'api/groupTherapy/types';
import { HoverTableStyles } from '../styles';
import Utilities from '../../../../api/lib/Utilities';

const NoDataComponent: JSX.Element = (
  <InlineText
    text="No Results Found."
    fontColor={FontColors.PRIMARY}
    fontSize={FontSizes.LARGE}
  />
);

export enum WorkListColumnNames {
  START_DATE = 'Start Date',
  TITLE = 'Title',
  PROGRAM_LABEL = 'Program',
  EXIT_PIN = 'Exit PIN'
}

const columns: TableColumn<GroupTherapySessionWorkListRow>[] = [
  {
    name: WorkListColumnNames.START_DATE,
    selector: (row) => row.startDate,
    sortable: true,
  },
  {
    name: WorkListColumnNames.TITLE,
    selector: (row) => row.groupTherapyTitle,
    sortable: true,
  },
  {
    name: WorkListColumnNames.PROGRAM_LABEL,
    selector: (row) => row.programLabel,
    sortable: true,
  },
  {
    name: WorkListColumnNames.EXIT_PIN,
    selector: (row) => row.groupTherapyPIN,
    sortable: false,
  },
];

const GroupTherapySessionWorkList = (props: GroupTherapySessionWorkListProps): JSX.Element => (
  <FlexContainer
    display={DisplayVariant.FLEX_COL}
    justify={JustifyVariant.START}
    align={AlignVariant.CENTER}
    extraClasses="group-therapy-session-work-list"
    testid="group-therapy-session-work-list-container"
  >
    {props.isLoading ? (
      <div className="loading-icon-overlay-container">
        <FontAwesomeIcon icon={faSpinner} className="fa-spin loading-icon-overlay" />
      </div>
    ) : (
      <DataTable
        dense
        highlightOnHover
        persistTableHead
        pagination
        sortIcon={<SortIcon />}
        customStyles={HoverTableStyles}
        columns={columns}
        data={props.data}
        noDataComponent={NoDataComponent}
        onRowClicked={props.onRowClicked}
        onSort={Utilities.setCellTitles}
      />
    )}
  </FlexContainer>
);

export default GroupTherapySessionWorkList;
